<template>
  <section>
    <base-card>
      <h2>Page is not translated yet</h2>
      <h5>
			Please come back later
      </h5>
    </base-card>
  </section>
</template>
