<template>
  <div>

    <base-dialog :show="isDeleting" title="Deleting your page" fixed>
      <p>Do you really want to delete user?</p>
      <h2 class="delete_title">{{ titleForDeletion }}</h2>
      <div class="d-flex justify-content-center mt-3">
        <base-button
          type="button"
          @click="deleteItem(idToDelete)"
          class="delete"
          >Yes</base-button
        >
        <base-button type="button" @click="cancelDeletion()" class="delete"
          >No</base-button
        >
      </div>
    </base-dialog>

    <section class="section section-sm">

<h2>Users</h2>
<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->
      <!-- Pagination Navbar-->
      <div class="row justify-content-center">
        <p class="mb-2">
          <strong> {{ total }} Records</strong>
        </p>
      </div>
      <nav aria-label="User navigation example  centered">
        <div class="row justify-content-center">
          <ul class="pagination">
            <li class="user-item">
              <span class="user-link move" @click="pagination(previousUser)">
					 <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style=" display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 3px; overflow: visible; " aria-hidden="true" role="presentation" focusable="false" > <g fill="none"><path d="m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"></path> </g> </svg >
					 </span>
            </li>
            <li
              :class="item.className"
              v-for="item in userNavbar"
              :key="item.user"
              :id="item.user"
            >
              <span class="user-link" @click="pagination(item.user)">{{
                item.user + 1
              }}</span>
            </li>
            <li class="user-item">
              <span class="user-link move" @click="pagination(nextUser)">
                <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style=" display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 3px; overflow: visible; " aria-hidden="true" role="presentation" focusable="false" > <g fill="none"> <path d="m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932" ></path> </g> </svg>
              </span>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /Pagination Navbar-->
      <!-- Items -->
      <div class="container">
        <div  v-if="items.users?.length > 0" >
          <div v-for="item in items.users" :key="item.id" class="item">
            <h3><span class="id">[{{item.id}}]</span> {{ item.email }}</h3>
            <div class="date">[{{ item.username }}]</div>
            <div class="row justify-content-center">
              <base-button type="button" @click="updateUser(item.id)">Update</base-button>
              <base-button
                type="button"
                @click="showDeleteConfirmation(item.id, item.email)"
                class="delete"
                >Delete</base-button
              >
            </div>
            <hr />
          </div>
        </div>
      </div>
      <!-- Items -->

    </section>
  </div>
</template>
<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";

export default {
  data () {
    return {
		appMessages:appMessages,
		lg:"sr",
      loading: false,
		showUserDate: false,
      items: [],
      userNavbar: [],
      totalUsers: 1,
      totalRecords: null,
      recordPerUser: 3,
      currentUser: 0,
      previousUser: 0,
      nextUser: 1,
	  isDeleting: false,
	  idToDelete: null,
	  titleForDeletion: "",
	  message: "",
    }
  },
  created () {
    this.items=[]
    this.loadItems()
	 //console.log(this.items)
  },
  mounted() {
   this.$store.commit("page/setShowCarusel",false);
  },

	watch: { 
		"$route.params.id"(newId, oldId) { 
			if (newId>0) this.loadItems() 
			} 
		},
  computed: {
    total() {
      var userNavbar = [];
      var totalRecords = this.items.count;
      var totalUsers = Math.ceil(totalRecords / this.recordPerUser);
      for (var i = 0; i < totalUsers; i++) {
        var className = "";
        if (i == 0) { className = "user-item active"; } else { className = "user-item";}
        var jo = { user: i, className: className };
        userNavbar.push(jo);
      }

      this.totalUsers = totalUsers;
      this.userNavbar = userNavbar;

//		console.log(this.userNavbar)
      return totalRecords;
    },
  },
  methods: {
    async loadItems () {

      this.loading = true
    	const payload = { limit: this.recordPerUser, offset: 0 };
      const ps = await api.getUsers(payload)
		this.items=ps
      this.loading = false
    },

    async pagination(p) {
      this.currentUser = p;
      var offset = p * this.recordPerUser;
    	const payload = {  limit: this.recordPerUser, offset: offset };
      this.items = await api.getUsers(payload)

      for (var i = 0; i < this.totalUsers; i++) {
        document.getElementById(i).className = "user-item";
      }
      document.getElementById(p).className = "user-item active";
      this.nextUser = p + 1;
      this.previousUser = p - 1;
      if (p == 0) {
        this.previousUser = p;
      }
      if (p == this.totalUsers - 1) {
        this.nextUser = p;
      }
      this.search = "";
    },

	updateUser(id) {
		this.$router.push({ path: `/adminupdateuser/${id}` });
	},

	showDeleteConfirmation(id, title) {
		this.isDeleting = true;
		this.idToDelete = id;
		this.titleForDeletion = title;
	},

	async deleteItem(id) {
		this.isDeleting = false;
  		await api.deleteUser(id)
		this.message = "Deletion is successfull";
		this.$router.push({ path: `/adminuserdashboard` });
	},
	cancelDeletion() {
		this.isDeleting = false;
	},
  goToDashboard() {
     this.$router.replace("/dashboard");
  },
  }
}
</script>

<style scoped>
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}

.delete {
  background-color: #bb0000;
}
.id {
	color: #aaa
}
.pagination {
	font-size: 0;
	line-height: 0;
	margin-bottom: -10px;
	margin-left: -10px;
}

.pagination:empty {
	margin-bottom: 0;
	margin-left: 0;
}

.pagination > * {

	cursor: pointer;
	display: inline-block;
	margin: 0 0 10px 10px;
}

.user-item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	font-weight: 700;
}

.user-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 48px;
	min-height: 48px;
	padding: 10px 10px;
	border: 0;
	border-radius: 6px;
	font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	line-height: 1.71429;
	background-color: #ff830a;
	color: #fff;
	transition: all 0.3s ease-in-out;
}

.user-link.button-winona * {
	line-height: inherit;
}

.user-link.button-winona .content-dubbed {
	margin-top: -1px;
}

.user-link:hover, .user-link:focus, .user-link:active {
	color: #fff;
	background-color: #783c00;
	box-shadow: none;
}

.user-item.active > .user-link, .user-item.active > .user-link:hover, .user-item.active > .user-link:focus, .user-item.active > .user-link:active {
	color: #fff;
	background-color: #783c00;
}

.user-item.disabled > .user-link, .user-item.disabled > .user-link:hover, .user-item.disabled > .user-link:focus, .user-item.disabled > .user-link:active {
	color: #74757f;
	background-color: #e8e9ee;
}

.user-item-control .icon::before {
	font-family: 'FontAwesome';
	font-size: 11px;
	line-height: 24px;
}

.user-item-control:first-child .icon::before {
	content: '\f053';
	margin-left: -1px;
}

.user-item-control:last-child .icon::before {
	content: '\f054';
	margin-right: -1px;
}

* + .pagination {
	margin-top: 35px;
}

@media (min-width: 992px) {
	* + .pagination {
		margin-top: 55px;
	}
}

@media (min-width: 1600px) {
	* + .pagination {
		margin-top: 70px;
	}
}
.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.back-go-dasboard {
	 background-color:#880000;
}
</style>
