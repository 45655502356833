import { createApp } from 'vue';

import router from './router/router.js';
import store from './store/index.js';
import axios from 'axios';

import VueAxios from 'vue-axios'

import App from './App.vue';
import BaseCard from './components/ui/BaseCard.vue';
import BaseSearch from './components/ui/BaseSearch.vue';
import BaseButton from './components/ui/BaseButton.vue';
import BaseBadge from './components/ui/BaseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
import PictureGallery from './components/ui/PictureGallery.vue';
import Rating from './components/ui/Rating.vue';

//import './assets/css/style.css';
//import './assets/css/plugins.css';
//import './assets/css/responsive.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
const app = createApp(App)
app.use(router);
app.use(store);
app.use(VueAxios, axios);

app.component('base-card', BaseCard);
app.component('base-search', BaseSearch);
app.component('base-button', BaseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog', BaseDialog);
app.component('picture-gallery', PictureGallery);

app.mount('#app');
