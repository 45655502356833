<template>
<!-- START-->
<!-- Language Drawer -->
<base-dialog :show="isLgChanged" :title="lgBoxTitle" fixed>
<div class="grid-container">
  <div class="grid-box" @click="setLanguage('sr')"><img src="/assets/images/icons/flags/sr.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('sr')">Srpska latinica</div>
  <div class="grid-box" @click="setLanguage('cp')"><img src="/assets/images/icons/flags/sr.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('cp')">Српска ћирилициа</div>
  <div class="grid-box" @click="setLanguage('sk')"><img src="/assets/images/icons/flags/sk.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('sk')">Slovenský</div>
  <div class="grid-box" @click="setLanguage('ro')"><img src="/assets/images/icons/flags/ro.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('ro')">Română</div>
  <div class="grid-box" @click="setLanguage('hu')"><img src="/assets/images/icons/flags/hu.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('hu')">Magyar</div>
  <div class="grid-box" @click="setLanguage('en')"><img src="/assets/images/icons/flags/en.svg" width="50" height="30"></div>
  <div class="grid-box" @click="setLanguage('en')">English</div>
</div>
</base-dialog>
<!-- End Language Drawer -->

<!-- Search Form Drawer -->
	<base-search :show="showSearchModal" fixed>
		<div class="search-container">
			<div class="search-input"> 
			<input v-model="searchInput" class="search__input" type="search" name="q" :placeholder=appMessages.searchForm.[lg] aria-label="Search" autocomplete="off" /> 
			</div>

			<div class="search-submit-container">
				<span @click="submitSearch()"> <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg></span>
			</div>
			<div class="search-close-container">
				<span @click="closeSearchModal()"> <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" > <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg> </span>
			</div>
		</div>
	</base-search>
	<div v-if="showSearchModal" class="xsearch">
		<div class="search__form">
			<form class="search-bar__form" action="#">
				<button class="go-btn search__button" type="submit">
					<i class="icon an an-search"></i>
				</button>
				<input class="search__input" type="search" name="q" value="" placeholder="Search entire store..." aria-label="Search" autocomplete="off"/>
			</form>
			<button type="button" class="search-trigger close-btn" data-bs-toggle="tooltip" data-bs-placement="left" title="Close" > <i class="icon an an-times"></i>ssss</button>
		</div>
	</div>
<!-- End Search Form Drawer -->

<!-- Main Header -->
	<div class="header-section clearfix animated hdr-sticky">
		<!-- Desktop Header -->
		<div class="header-1">
			<!-- Top Header -->
			<div class="top-header">
				<div class="container-fluid">
					<div class="row">

						<div class="col-10 col-sm-8 col-md-7 col-lg-4">
<!-- Langages -->
<div class="lg-picker float-start">
	<div @click="changeLanguage()" class="lg-pick">
{{ appMessages.currentLg.[lg] }}: <img :src="lgBoxFLagSrc" class="active-flag" width="50" height="25"></div>
</div>
<!--End Langages -->
							<p class="phone-no float-start"> <i class="icon an an-phone me-1"></i ><a href="tel:+381 13 661-122">+381 13 661-122</a></p>
						</div>
						<div class="col-sm-4 col-md-4 col-lg-4 d-none d-md-none d-lg-block">
							<div class="text-center">
								<p class="top-header_middle-text">{{ appMessages.appTitle.[lg] }}</p>
							</div>
						</div>
						<div
							class="col-2 col-sm-4 col-md-5 col-lg-4 text-end d-none d-sm-block d-md-block d-lg-block"
						>
							<div class="header-social">
								<ul class="justify-content-end list--inline social-icons">
									<li><a class="social-icons__link" href="https://www.facebook.com/opstina.kovacica.1/" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Facebook" ><i class="icon an an-facebook"></i> <span class="icon__fallback-text" >Facebook</span></a></li>
									<li><a class="social-icons__link" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Instagram" ><i class="icon an an-instagram"></i> <span class="icon__fallback-text">Instagram</span></a></li>
									<li><a class="social-icons__link" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="YouTube" ><i class="icon icon an an-youtube"></i> <span class="icon__fallback-text" >YouTube</span ></a></li>
								</ul>
							</div>
						</div>
						<div class="col-2 col-sm-4 col-md-5 col-lg-4 text-end d-block d-sm-none d-md-none d-lg-none">
							<!-- Mobile User Links -->
							<div class="user-menu-dropdown">
								<span class="user-menu"><i class="an an-user-alt"></i></span>
								<ul class="customer-links list-inline" style="display: none">
									<li class="item"><a href="login.html">Login</a></li>
									<li class="item"> <a href="register.html">Register</a></li>
								</ul>
							</div>
							<!-- End Mobile User Links -->
						</div>
					</div>
				</div>
			</div>
			<!-- End Top Header -->

			<!-- Header -->
			<div class="header-wrap d-flex">
				<div class="container-fluid menu-bar">
					<div class="row align-items-center">
						<div class="col-4 col-sm-4 col-md-4 col-lg-8 d-block d-lg-none">
							<button @click="togleMobileMenu()" type="button" class="btn--link site-header__menu js-mobile-nav-toggle mobile-nav--open" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Menu"> <i class="icon an an-times"></i><i class="icon an an-bars"></i></button>
							<!-- Mobile Search -->
							<div class="site-header__search d-block d-lg-none mobile-search-icon">
								<button @click="openMobileSearch()" type="button" class="search-trigger" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Search"><i class="icon an an-search"></i></button>
							</div>
							<!-- End Mobile Search -->
						</div>
						<!-- Desktop Logo -->
						<div class="logo col-4 col-sm-4 col-md-4 col-lg-2 align-self-center"> <img src="/assets/images/logo.png" alt="Opština Kovačica" title="Opština Kovačica"/></div>
						<!-- End Desktop Logo -->
						<!-- Desktop Navigation -->
						<div class="col-2 col-sm-3 col-md-3 col-lg-8 d-none d-lg-block">
							<!-- Desktop Menu -->
	<desktop-menu></desktop-menu>
							<!-- End Desktop Menu -->
						</div>
						<!-- End Desktop Navigation -->
						<!-- Right Side -->
						<div class="col-4 col-sm-4 col-md-4 col-lg-2">
							<div class="right-action d-flex-align-center justify-content-end">
								<!-- Search -->
								<div class="item site-header__search d-none d-lg-block">
									<button @click="togleSearchInput()" type="button" class="search-trigger" data-bs-toggle="tooltip" data-bs-placement="bottom" :title= appMessages.search.[lg]><i class="icon an an-search"></i></button>
								</div>
								<!-- End Search -->
								<!-- User Links -->
								<div @click="goToLoginPage" class="item user-menu-dropdown d-none d-sm-block d-md-block d-lg-block">
									<span class="user-menu" data-bs-toggle="tooltip" data-bs-placement="bottom" :title=appMessages.dashboard.[lg] ><i class="icon an an-user-alt"></i></span>
								</div>
								<!-- End User Links -->
							</div>
						</div>
						<!-- End Right Side -->
					</div>
				</div>
			</div>
			<!-- End Header -->
		</div>
		<!-- End Desktop Header -->
	</div>
	<!-- End Main Header -->

	<div v-if="showMobileMenu" role="navigation">
	<!-- Mobile Menu -->
	<mobile-menu></mobile-menu>
	<!-- End Mobile Menu -->
	</div>
</template>

<script>
import { appMessages } from "../../translations/messages";

import DesktopMenu from './MenuDesktop.vue';
import MobileMenu from './MenuMobile.vue';

export default {
components: {
  DesktopMenu, 
  MobileMenu, 
},
	data() {
		return {
			showMobileMenu: false,
			showSearchModal: false,
			isLgChanged: false,
			searchInput: "",
			mobileMenuClass: "mobile-nav-wrapperx",
			showMobileSubmenu: [false, false, false, false],
			rowId: 10,
			appMessages: appMessages,
			lgBoxTitle:"",
			lgBoxFLagSrc:"",
			lg: "sr",
			currentMenuDesktopComponent: "MenuDesktopSr",
			currentMenuMobileComponent: "MenuMobile"
			//currentMenuMobileComponent: ""
		};
	},
	computed: {
		isLoggedIn() {
			return this.$store.getters.isAuthenticated;
		},
	},

	created() {
		//console.log("Mobile menu created");
		//this.currentMenuMobileComponent="MenuDesktopHu"
	},
	mounted() {
		this.lg = this.$store.getters["lg"];
		this.lgBoxFLagSrc=`/assets/icons/flags/${this.lg}.svg`
		this.lgBoxTitle= appMessages.currentLg.[this.lg] 
		//this.currentMenuMobileComponent="MenuDesktopHu"
	},
	watch: {
		"$route.params.id"(newId, oldId) {
			//console.log("WATCHER CALLED");
			const ppp = this.$route.path.substring(
				this.$route.path.indexOf("/") + 1,
				this.$route.path.lastIndexOf("/")
			);
			if (newId > 0 && ppp == "listpageincategory") {
				this.showMobileMenu = false;
			}
			//console.log(ppp)

			//this.initialLoadItems()
		},
	},
	methods: {
		togleMobileMenu() {
			this.showMobileMenu = !this.showMobileMenu;
		},
		closeMobileMenu() {
			this.showMobileMenu = false;
		},
		openMobileSearch() {
			this.showSearchModal = true;
		},
		closeSearchModal() {
			this.showSearchModal = false;
		},
		submitSearch() {
			this.showSearchModal = false;
			this.$store.commit("page/setSearchInput", this.searchInput);
			this.$router.replace("/listpageinsearch");
		},

		goToLoginPage() {
			if (this.isLoggedIn) {
				this.$router.replace("/dashboard");
			} else {
				this.$router.replace("/login");
			}
		},
		togleSearchInput() {
			this.$router.replace("/home");
			this.showSearchModal = true;
		},

		changeLanguage() {
			this.lgBoxTitle= appMessages.availableLg.[this.lg] 
			this.isLgChanged=true;
			//console.log(lg);
		},
		setLanguage(lg) {
			this.lg = lg;
			this.$store.commit("setLg",lg);
			this.isLgChanged=true;
			this.lgBoxFLagSrc=`/assets/icons/flags/${this.lg}.svg`
			this.lgBoxTitle= appMessages.availableLg.[this.lg] 

			setTimeout(()=>{this.isLgChanged=false;},200)
			console.log(this.lg);
			this.$router.replace("/");
		},
	},
};
</script>

<style>
.router-link-active {
	color: #0000ff;
	border: 1px solid red;
}
.menu-bar {
	background-color: #eeeeee;
}

.search-container {
	display: flex;
	cursor: pointer;
}
.search-input {
	flex: 14;
}
.search-close-container {
	flex: 1;
	color: #888888;
}
.search-submit-container {
	flex: 1;
	color: #888888;
}
.mobile-submenu {
	display: block;
}
/*
.lg-select {
	background-color: #880000;
	pointer: cursor;
}
.lg-select:hover {
	background-color: #ff0000;
	pointer: cursor;
}
*/
.lg-menu {
	background-color: #222;
	cursor: pointer;
}

.lg-menu:hover {
	background-color: #a00;
}
.lg-box {
	 font-size: 2rem;
	 text-align: center;
}
.lg-flag {
	width: 30%; 
  border: 1px solid #ccc;
	display:block;
  margin-left:auto;
  margin-right:auto;
}
.lg-pick {
	cursor: pointer;
  margin-right:1rem;
	font-size:0.9rem;
}
.grid-container{
  margin: 10px auto;
  width:80%;
  height:500px;
  background-color:#fff;
  display:grid;
  grid-template-columns: 20% 70%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.grid-box{
		cursor: pointer;
    background-color:#ccc;
    padding:10px;
    border-radius:10px;
    color:#000;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    font-family:sans-serif;
}
.active-flag {
	 width:20px;
	 margin-right:5px;
}

</style>
