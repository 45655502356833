<template>
  <div>
    <section class="section section-sm">
      <div class="container">
        <h1>Page Category Dashboard</h1>
<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->
		  <p>Update category title, add new sub category or delete existing category.</p>
		  <span class="alert">For deletion please remove all pages from that category!</span>
		  <p></p>
    <ul id="page-category-tree">
      <tree-item-change :item=treeDataDB ></tree-item-change>
	</ul>
  </div>
    </section>
  </div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import TreeItemChange from './TreeItemChange.vue';

export default {

  components: {
    TreeItemChange
  },
  data() {
    return {
		appMessages:appMessages,
		lg:"sr",
      items: [],
		loading: false, 
      tree: "",
		//treeDataDB: { title: "Click on the category name to list pages ", id:0, children:[] },
		treeDataDB: {},
    };
  },
  mounted() {
  },

  created() {
    this.loadPageCategories()
    this.apiUrl = process.env.VUE_APP_API_URL;

  },
  computed: {
  },

  methods: {

	  async loadPageCategories (id) {
		  this.loading = true
		  this.items = await api.getPageCategories()
		  this.loading = false

			this.tree=this.items;
			this.convertToJSONTree(this.items)
			this.treeDataDB.children=this.tree;
	  },
	  convertToJSONTree  (data)  {
		  const tree = data.map(e => ({...e}))
			  .sort((a, b) => a.id - b.id)
			  .reduce((a, e) => {
					  a[e.id] = a[e.id] || e;
					  a[e.parentId] = a[e.parentId] || {};
					  const parent = a[e.parentId];
					  parent.children = parent.children || [];
					  parent.children.push(e);
					  return a;
					  }, {})
		  ;
		  this.tree= Object.values(tree) .find(e => e.id === undefined).children;
	  },
    goToMyPages() {
      this.$router.replace("adminlistmypage");
    },
    goToPage(router) {
      this.$router.push({ path: `/${router}` });
      //this.$router.push({ path: `/${router}/${id}` });
    },
  goToDashboard() {
     this.$router.replace("/dashboard");
  },
  } // end of methods
};
</script>

<style scoped>
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}

.delete {
  background-color: #bb0000;
}
.delete:hover {
  background-color: #ff0000;
}
.delete_title {
  text-align: center;
}
.id {
  color: gray;
}
.centered {
  text-align: center;
  display: block;
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.passivePageCategory {
  font-weight: normal;
  color: #000000;
}
.alert {
  color: #cc0000;
}

.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.back-go-dasboard {
	 background-color:#880000;
}
</style>
