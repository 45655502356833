<template>
<div>

<div v-html="item.content"></div>
<div class="container">
                        <div class="section-header text-center">
                            <h2 class="h2">{{appMessages.latest.[lg]}}</h2>
                        </div>
    <div v-for="item in items" :key="'pt_'+item.id" class="item">
            <h3><router-link  :to="`/showpage/${item.id}`">{{ item.title }}</router-link></h3>
            <div class="date">{{ item.pageDate }}</div>
				<div v-html="item.content"></div>
         <hr />
       </div>
</div>
<!-- <home-banner-slider>

</home-banner-slider> -->
<!-- Top Banner Slider -->
<div class="flex-container">
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_1.png" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_2.png" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_3.png" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_4.png" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_5.png" alt="Logo" title="" /></a></div>
  <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/banner_6.png" alt="Logo" title="" /></a></div>
</div>
<!-- End Top Banner Slider -->

 <!-- Collection -->
 <div class="collection-box tle-bold section">
 <div class="container">
 <div class="section-header text-center">
 <h2 class="h2">{{ appMessages.sisterCity.[lg] }}</h2>
 </div>
 <!--  -->

 <div class="flex-container">

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/montfalcone.jpg" src="/assets/images/frontpage/montfalcone.jpg" alt="Monfalcone" title="Monfalcone" />
 <a href="https://www.comune.monfalcone.go.it/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">Montfalcone<span>{{appMessages.italy.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/banska_bistrica.jpg" src="/assets/images/frontpage/banska_bistrica.jpg" alt="{{appMessages.banskaBistrica.[lg]}}" title="{{appMessages.banskaBistrica.[lg]}}" />
 <a href="https://www.banskabystrica.sk/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">{{appMessages.banskaBistrica.[lg]}}<span>{{appMessages.slovakia.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/rekas.jpg" src="/assets/images/frontpage/montfalcone.jpg" alt="{{appMessages.rekas.[lg]}}" title="{{appMessages.rekas.[lg]}}" />
 <a href="https://primariarecas.ro/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">{{appMessages.rekas.[lg]}}<span>{{appMessages.romania.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/sveti_djordje.jpg" src="/assets/images/frontpage/sveti_djordje.jpg" alt="{{appMessages.sumskiSvetDjordje.[lg]}}" title="{{appMessages.sumskiSvetDjordje.[lg]}}" />
 <a href="https://www.sepsiszentgyorgyinfo.ro/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">{{appMessages.sumskiSvetDjordje.[lg]}}<span>{{appMessages.romania.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/malo_trnovo.jpg" src="/assets/images/frontpage/malo_trnovo.jpg" alt="Malo Trnovo" title="Malo Trnovo" />
 <a href="https://trnovo-rs.com/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">Malo Trnovo<span>{{appMessages.bulgaria.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/lapovo.jpg" src="/assets/images/frontpage/lapovo.jpg" alt="Lapovo" title="Lapovo" />
 <a href="http://www.lapovo.rs" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">Lapovo<span>{{appMessages.serbia.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 <div class="flex-item">
 <div class="collection-grid-item">
 <img class="blur-up lazyload" data-src="/assets/images/frontpage/arilje.jpg" src="/assets/images/frontpage/arilje.jpg" alt="Arilje" title="Arilje" />
 <a href="https://arilje.org.rs/" target="_blank" class="collection-grid-item__title-wrapper">
 <div class="title-wrapper">
 <h3 class="collection-grid-item__title fw-bold">Arilje<span>{{appMessages.serbia.[lg]}}</span></h3>
 </div>
 </a>
 </div>
 </div>

 </div>

 </div>
 </div>
 <!-- End Collection -->

                <!-- Store Feature -->
                <div class="store-feature style2 section">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ul class="display-table store-info">
                                    <li class="display-table-cell">
                                        <div class="store-info-text">
                                            <h5>{{appMessages.allwaysWithYou.[lg]}}</h5>
                                            <span class="sub-text">{{appMessages.call.[lg]}}: 013 661-122<br>E-mail: info@kovacica.com</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Store Feature -->

<!-- Bottom Banner Slider -->
<div class="flex-container">
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/b_b_1.jpg" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/b_b_2.jpg" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/b_b_3.jpg" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/b_b_4.jpg" alt="Logo" title="" /></a></div>
    <div class="logo-bar__item"><a href="#" class="d-block"><img src="assets/images/frontpage-banners/b_b_5.jpg" alt="Logo" title="" /></a></div>
</div>
<!-- End Bottom Banner Slider -->
            <!-- End Body Content -->
</div>
</template>

<script>

import api from "../../api/client";
import { appMessages } from "../translations/messages";
import { convertDateToLg } from "../helpers/index.js";

//console.log(appMessages.latest.hu)
export default {
data() {
return { 
	item: "",
	items: [],
	frontpageContent: "",
	lg:"",
	appMessages:appMessages,
};
},
computed: {
	 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
	 }
},
created() {

    this.loadHomeItem(this.lgs)
    this.loadItems(this.lgs)
    this.apiUrl = process.env.VUE_APP_API_URL;
		//console.log("Home created called")
},
watch: {
    lgs  (newLg, oldLg) {
     //console.log(`We have ${newLg} fruits now, yay!`)
    this.loadHomeItem(this.lg)
    this.loadItems(this.lg)
    }
},
mounted() {
   this.$store.commit("page/setShowCarusel",true);
},
methods: {
    async loadHomeItem (lg) {
      this.loading = true
      const r = await api.getPage(1)
      this.item = r.page
      this.loading = false
    },
    async loadItems (lg) {
      this.loading = true
      const r = await api.listPageInHomePage(lg)
      this.items = r.pages
      this.loading = false

		this.items.forEach((item, i, array)=> {
			array[i].pageDate=convertDateToLg(item.pageDate,this.lg)
		})
    },
},
};
</script>

<style scoped>
.flex-container {
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
}
.flex-item {
	width: 290px;
	margin: 0.5rem;
	border-radius: 1px;
	border: 1px solid #888;
	padding: 3px; 
}
.date {
color:#888;
}
</style>

