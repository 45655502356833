const API_URL = process.env.VUE_APP_API_URL;
const SITE_URL = process.env.VUE_APP_SITE_URL;
import axios from 'axios';
import router from "../../router/router"
export default {

    namespaced: true,
    state() {
        return {
			  showCarusel:true,
			  searchInput:"",
            idCurrent: 1,
            pageCategoryIds: [],
            apiCounter: 0,
            ratingAvg: null,
            ratingNum: null,
            reservedDays: [],
            totalNumberOfItems: null,
            items: [],
            comments: [],
            item: { titleSR: "111", contentSR: "2222", address: "3333", rooms: null, pageCategoryIds: [] },
            itemFromDatabase: { titleSR: "", contentSR: "", address: "", rooms: null, pageCategoryIds: [] },
            idCreated: null,
            apiError: { error: false, message: 'NINCS HIBA' },
            test: "TEST",
            lastFetch: null,
            page: null,
            mypages: [],
            apiErrorMessageLocal: "",
            apiErrorLocal: false,
            message: "",
        };
    },

    //GETTERS
    getters: {
        showCarusel(state) {
            return state.showCarusel;
        },
        searchInput(state) {
            return state.searchInput;
        },
        idCurrent(state) {
            return state.idCurrent;
        },
        pageCategoryIds(state) {
            return state.pageCategoryIds;
        },
        items(state) {
            return state.items;
        },
        myItems(state) {
            return state.myItems;
        },
        reservationsForme(state) {
            return state.reservationsForme;
        },
        item(state) {
            return state.item;
        },
        itemFromDatabase(state) {
            return state.item;
        },
        totalNumberOfItems(state) {
            return state.totalNumberOfItems;
        },
        comments(state) {
            return state.comments;
        },

        ratingAvg(state) {
            return state.ratingAvg;
        },
        apiCounter(state) {
            return state.apiCounter;
        },
        ratingNum(state) {
            return state.ratingNum;
        },
        shouldUpdate(state) {
            const lastFetch = state.lastFetch;
            if (!lastFetch) {
                return true;
            }
            const currentTimeStamp = new Date().getTime();
            return (currentTimeStamp - lastFetch) / 1000 > 60;
        },

        idCreated(state) {
            return state.idCreated;
        },
        apiError(state) {
            return state.apiError;
        },
        message(state) {
            return state.message;
        },
        reservedDays(state) {
            return state.reservedDays;
        },
        ooo(state) {
            return state.ooo;
        },

    },
    // \GETTERS

    // MUTATIONS
    mutations: {

        setSearchInput(state, payload) {
            state.searchInput = payload;
        },
        setShowCarusel(state, payload) {
            state.showCarusel = payload;
        },
        setIdCurrent(state, payload) {
            //state.idCurrent = payload;
            state.item.titleSR = "New Title";
			  //console.log("mutation setIdCurrent called")
        },
        setPageCategoryIds(state, payload) {
			  if (!state.pageCategoryIds.includes(payload)) {
				  state.pageCategoryIds.push(payload)
				  //console.log("PUSHED")
			  }
			  else {
					const index = state.pageCategoryIds.indexOf(payload);
					if (index > -1) {state.pageCategoryIds.splice(index, 1)}
			  }
        },

        deletePageCategoryIds(state) {
			  state.pageCategoryIds=[]
        },

        registerPage(state, payload) {
            state.pages.push(payload);
        },
        setPages(state, payload) {
            state.pages = payload;
        },
        setMyPages(state, payload) {
            state.mypages = payload;
        },
        setFetchTimestamp(state) {
            state.lastFetch = new Date().getTime();
        },
        setApiCounter(state, payload) {
            state.apiCounter = payload;
        },
        setIdCreated(state, payload) {
            state.idCreated = payload;
        },
        setItems(state, payload) {
            state.items = payload;
        },
        setComments(state, payload) {
            state.comments = payload;
        },
        setMyItems(state, payload) {
            state.myItems = payload;
        },
        setReservationForMe(state, payload) {
            state.reservationsForme = payload
        },
        setItem(state, payload) {
            state.item = payload;
        },
        setAaa(state, payload) {
           state.item.titleSR = payload;
        },
        setBbb(state, payload) {
           state.item.contentSR = payload;
        },
        setCcc(state, payload) {
           state.item.address = payload;
        },
        setDdd(state, payload) {
           state.item.rooms = payload;
        },
        setEee(state, payload) {
           state.item.pageCategoryIds = payload;
        },
        setTotalNumberOfItems(state, payload) {
            state.totalNumberOfItems = payload;
        },
        setRatingAvg(state, payload) {
            state.ratingAvg = payload;
        },
        setRatingNum(state, payload) {
            state.ratingNum = payload;
        },
        setItemFromDatabase(state, payload) {
            state.itemFromDatabase = payload;
        },
        setApiError(state, payload) {
            state.apiError = payload;
        },
        //****************
        setApiErrorMessageLocal(state, payload) {
            state.apiErrorMessageLocal = payload.message;
        },
        setApiErrorLocal(state, payload) {
            state.apiErrorLocal = payload.error;
        },

        setMessage(state, payload) {
            state.message = payload;
        },
        setReservedDays(state, payload) {
            state.reservedDays = payload;
        },
        setOoo(state, payload) {
            state.ooo = payload;
        },

    },
    //\MUTATIONS

    //ACTIONS
    actions: {

        loadMyItems(context) {
            const url = API_URL + "/page/own";
            const config = { headers: { 'Authorization': `Basic ${context.rootGetters.token}` } };
            axios.get(url, config)
                .then(response => {
                    response.data;
                    context.commit('setItems', response.data)
                })
                .catch((error) => {
                    if (error.response) {
                        context.commit('setApiError', { error: true, message: error.response.data.error });

                    } else if (error.request) {
                        context.commit('setApiError', { error: true, message: 'Server error. Please try later!' });
                    } else {
                        console.log('Error', error.message);
                    }
                })
        },

        //*****************************************
    }
    //\ACTIONS
};

