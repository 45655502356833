<template>
	<page-header></page-header>
	<div id="page-content">
		<div v-if="showCarusel"><home-banner-slider></home-banner-slider></div>
		<!-- VUE APP -->
		<div class="product-rows section main-vue-container">
			<div class="container">
				<div class="row">
					<router-view></router-view>
				</div>
			</div>
		</div>
		<!--/ VUE APP -->
	</div>
	<page-footer></page-footer>
</template>

<script>

import PageHeader from './components/layout/PageHeader.vue';
import PageFooter from './components/layout/PageFooter.vue';
import HomeBannerSlider from './components/layout/HomeBannerSlider.vue';

export default {
components: {
  PageHeader,
	HomeBannerSlider,
   PageFooter
},
data() {
	return {
//			showCarusel: false,
		};
	},
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
	 showCarusel() {
		 return this.$store.getters.['page/showCarusel']
	 }

  },
  created() {
    this.$store.dispatch('tryLogin');
  },

mounted() {
	//this.showCarusel=this.$store.getters.['pages/showCarusel'];
},
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/home');
      }
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
.main-vue-container {
	 padding-top:0;
}

</style>
