<template>
	<nav class="grid__item" id="AccessibleNav">
		<ul id="siteNav" class="d-flex flex-wrap site-nav medium left ms-0 hidearrow my-menu" >
			<li> <router-link to="/home" >{{ appMessages.menuHome.[lgs] }}</router-link></li>
			<li class="lvl1 parent dropdown"> <router-link to="/listpageincategory/1" >{{ appMessages.menuFirst.[lg] }}<i class="an an-angle-down"></i ></router-link>
				<ul class="dropdown">
					<li  v-for="item in menu[0][lgs]">
						<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</ul>
			</li>
			<li class="lvl1 parent dropdown">
				<router-link to="/listpageincategory/2">{{ appMessages.menuSecond.[lgs] }}<i class="an an-angle-down"></i></router-link>
					<ul class="dropdown">
					<li  v-for="item in menu[1][lgs]">
						<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</ul>
			</li>
			<li class="lvl1 parent dropdown">
				<router-link to="/listpageincategory/6">{{ appMessages.menuThird.[lgs] }}<i class="an an-angle-down"></i></router-link>
					<ul class="dropdown">
					<li  v-for="item in menu[2][lgs]">
						<router-link :to=item.root>{{item.title}}</router-link>
					</li>
					</ul>
			</li>
			<li class="lvl1 parent dropdown">
				<router-link to="/listpageincategory/8">{{ appMessages.menuFourth.[lgs] }}<i class="an an-angle-down"></i></router-link>
					<ul class="dropdown">
					<li  v-for="item in menu[3][lg]">
						<router-link :to=item.root>{{item.title}}</router-link>
					</li>
					</ul>
				</li>
			</ul>
		</nav>
</template>

<script>
import { appMessages } from "../../translations/messages";
import { menuGlobal } from "../../translations/menu";
export default {
	data() {
		return {
			appMessages: appMessages,
			menu: menuGlobal.menu,
		 	lg:"",
			showMobileSubmenu: [false, false, false, false],
		};
	},
	computed: {
		 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
		 }
	},

	created() {
	},
	mounted() {
		this.lg = this.$store.getters["lg"];
	},
	methods: {
	},
};
</script>

<style>

</style>
