<template>
<!-- Footer -->
<footer id="footer" class="footer-2">
<div class="site-footer">
<div class="footer-top">
<div class="container">
<!-- Footer Links -->
<div class="row col-grid-5">
<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 custom-block">
<div class="footer-logo"><img src="/assets/images/logo-white.png" :alt=appMessages.appTitle.[lg] :title= appMessages.appTitle.[lg] width="130" /></div>
<div class="text mb-2 mb-md-0 mt-3">{{ appMessages.appTitle.[lg] }}</div>
</div>
<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 footer-links">
<h4 class="h4">{{ appMessages.popularPages.[lgs] }}</h4>
<ul>
	<li><router-link to="/home">{{ appMessages.menuHome.[lg] }}</router-link></li>
	<li><router-link to="/listpageincategory/12">{{ appMessages.footer1.[lg] }}</router-link></li>
	<li><router-link to="/listpageincategory/12">{{ appMessages.footer2.[lg] }}</router-link></li>
</ul>
</div>

<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 footer-links">
<h4 class="h4">{{ appMessages.documents.[lg] }}</h4>
<ul>
	<li><router-link to="/listpageincategory/16">{{ appMessages.footer3.[lg] }}</router-link></li>
	<li><router-link to="/listpageincategory/12">{{ appMessages.footer4.[lg] }}</router-link></li>
	<li><router-link to="/showpage/15">{{ appMessages.footer5.[lg] }}</router-link></li>
</ul>
</div>
<div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 newsletter">
<div class="display-table">
<div class="display-table-cell footer-newsletter">
<label class="h4">{{ appMessages.contact.[lg] }}</label>
<p>{{ appMessages.appTitle.[lg] }}</p>
<p>{{ appMessages.street.[lg] }} 50</p>
<p>{{ appMessages.phone.[lg] }}: +381 13 661-047</p>
</div>
</div>
</div>
<div class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-2 socialPayment">
<div class="item">
<h4 class="h4">{{ appMessages.socialNetworks.[lg] }}</h4>
<ul class="list--inline site-footer__social-icons social-icons">
<li><a class="social-icons__link d-inline-block" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Facebook"><i class="icon an an-facebook"></i></a></li>
<li><a class="social-icons__link d-inline-block" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Twitter"><i class="icon an an-twitter"></i> <span class="icon__fallback-text">Twitter</span></a></li>
<li><a class="social-icons__link d-inline-block" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="Instagram"><i class="icon an an-instagram"></i> <span class="icon__fallback-text">Instagram</span></a></li>
<li><a class="social-icons__link d-inline-block" href="#" target="_blank" data-bs-toggle="tooltip" data-bs-placement="top" title="YouTube"><i class="icon an an-youtube"></i> <span class="icon__fallback-text">YouTube</span></a></li>
</ul>
</div>
<div class="item">
<h4 class="h4 mt-lg-4">E-mail</h4>
<p>info@kovacica.com</p>

</div>
</div>
</div>
<!-- End Footer Links -->
</div>
</div>

<div class="footer-bottom">
<div class="container">
<div class="row">
<!-- Footer Copyright -->
<div class="col-12 col-sm-12 col-md-12 col-lg-12 copyright text-center">
&copy; 2021 {{ appMessages.appTitle.[lg] }} - {{ appMessages.copyright.[lg] }} 
</div>
<!-- End Footer Copyright -->
</div>
</div>
</div>
</div>
</footer>
<!-- End Footer -->
</template>

<script>
import { appMessages } from "../../translations/messages";
export default {

data() {
return {
	appMessages: appMessages,
	lg: ""
};
},
computed: {
 lgs () {
		this.lg=this.$store.getters["lg"]
  	return this.$store.getters["lg"]
 }
},

created() {

},
mounted() {
	this.lg = this.$store.getters["lg"];
},
methods: {
}
}
</script>

<style scoped>
</style>

