export default {
    userEmail(state) {
        return state.userEmail;
    },
    userRealId(state) {
        return state.userRealId;
    },
    token(state) {
        return state.token;
    },
    lg(state) {
        return state.lg;
    },
    isAuthenticated(state) {
        if (state.token != localStorage.token) { return false }
        return !!state.token;
    },
    didAutoLogout(state) {
        return state.didAutoLogout;
    },
    apiSuccess(state) {
        return state.apiSuccess;
    },
    apiError(state) {
        return state.apiError;
    },
    apiErrorMessage(state) {
        return state.apiErrorMessage;
    },
    logedInUserEmail(state) {
        return state.userEmail;
    },
    userPagePrivilege(state) {
        return state.userPagePrivilege;
    },
    userModulePrivilege(state) {
        return state.userModulePrivilege;
    }

};
