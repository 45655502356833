<template>
  <div>

    <base-dialog :show="isDeleting" title="Deleting your page" fixed>
      <p>Do you really want to delete your page?</p>
      <h2 class="delete_title">{{ titleForDeletion }}</h2>
      <div class="d-flex justify-content-center mt-3">
        <base-button
          type="button"
          @click="deleteItem(idToDelete)"
          class="delete"
          >Yes</base-button
        >
        <base-button type="button" @click="cancelDeletion()" class="delete"
          >No</base-button
        >
      </div>
    </base-dialog>

    <section class="section section-sm">

<h2>{{ appMessages.pageListInCategory.[lg] }}</h2>
<div class="category" >{{ appMessages.category.[lg] }}: {{pagePath}}</div>
<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->
      <!-- Pagination Bar-->
      <div id="paginatin-bar"  v-if="showPaginationBar">
      <div class="pagination-container">
        <p class="mb-2"> <strong>{{ totalRecords }} Records [Page: {{currentPage}} / {{totalPages}}]</strong></p>
      </div>
      <div class="pagination-container">

<div class="page-item" id="previousDecade" v-if="showPreviousDecade">
  <span class="page-link move" @click="previousDecade()">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
</svg>
				  </span>
            </div>

            <div class="page-item" id="previousPage" v-if="showPreviousPage">
              <span class="page-link move" @click="pagination(previousPage)">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
</svg>
				  </span>
            </div>

            <div :class="item.className" v-for="item in paginationBar" :key="item.page" :id="item.page">
              <span class="page-link" @click="pagination(item.page)">{{ item.page}}</span>
            </div>

            <div class="page-item" id="nextPage" v-if="showNextPage">
              <span class="page-link move" @click="pagination(nextPage)">
<svg xmlns="http://www.w3.org/2000/svg" class="" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
</svg>
              </span>

            </div>
            <div class="page-item"  id="nextDecade" v-if="showNextDecade">
              <span class="page-link move" @click="nextDecade()">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
</svg>
				  </span>
            </div>
        </div>
     </div>
      <!-- /Pagination Bar-->
      <!-- Items -->
      <div class="container">
      <div>{{ message }}</div>
        <div  v-if="items.pages?.length > 0" >
          <div v-for="item in items.pages" :key="item.id" class="item">
            <div class="admin-list-title"><span class="id">[{{item.id}}]</span> {{ item.title }}</div>
            <div class="date">[{{ item.pageDate }}]</div>
            <div class="flex-container">
              <base-button type="button" @click="updatePage(item.id)">{{ appMessages.pageUpdate.[lg] }}</base-button>
              <base-button
                type="button"
                @click="goToPage('showpage', item.id)"
                >{{ appMessages.showPage.[lg] }}</base-button
              >
              <base-button
                type="button"
                @click="showDeleteConfirmation(item.id, item.title)"
                class="delete"
                >{{ appMessages.delete.[lg] }}</base-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Items -->

    </section>
  </div>
</template>
<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
export default {
  data () {
    return {
		appMessages:appMessages,
		lg:"sr",
      loading: false,
		showPageDate: false,
		pagePath: "",
      items: [],

      pageNavbar: [],
      totalPages: 1,
      totalRecords: null,
      recordPerPage: 200,
      currentPage: 0,
      previousPage: 0,
      nextPage: 1,
	  isDeleting: false,
	  idToDelete: null,
	  titleForDeletion: "",
	  message: "",
      paginationBar: [],
      paginationBarAll: [],
      totalPages: 1,
      totalRecords: null,
      recordPerPage: process.env.VUE_APP_RECORD_PER_PAGE,
      currentPage: 1,
      previousPage: 1,
      nextPage: 2,

		showPaginationBar: false,

		totalDecades:1,
		currentDecade:1,

		showPreviousPage: false,
		showNextPage: true,
		showPreviousDecade: false,
		showNextDecade: false,

		firstButton:1,
		firstButtonInCurrentPaginationBar:1,
		lastButtonInCurrentPaginationBar:10,
    }
  },
  created () {

    this.items=[]
    this.loadItems()
    this.initialLoadItems()
  },
  mounted() {
  },

	watch: { 
		},
  computed: {
    total() {
      var pageNavbar = [];
      var totalRecords = this.items.count;
      var totalPages = Math.ceil(totalRecords / this.recordPerPage);
      for (var i = 0; i < totalPages; i++) {
        var className = "";
        if (i == 0) { className = "page-item active"; } else { className = "page-item";}
        var jo = { page: i, className: className };
        pageNavbar.push(jo);
      }
      this.totalPages = totalPages;
      this.pageNavbar = pageNavbar;

      return totalRecords;
    },
  },
  methods: {
    async loadItems () {

      this.loading = true
    	const payload = { id:this.$route.params.id, limit: this.recordPerPage, offset: 0 };
      const ps = await api.listPageInCategoryAdmin(payload)
		this.items=ps
		//this.showPageDate=this.items.category.showPageDate
		this.pagePath=this.items.category.path
      this.loading = false
    },

    async pagination(p) {
      this.currentPage = p;
      var offset = (this.currentPage-1) * this.recordPerPage;
    	const payload = { id:this.$route.params.id, limit: this.recordPerPage, offset: offset };
      this.items = await api.listPageInCategoryAdmin(payload)

      this.nextPage = p + 1;
      this.previousPage = p - 1;
      if (p == this.firstButtonInCurrentPaginationBar) { this.previousPage = p; }
      if (p == this.lastButtonInCurrentPaginationBar) { this.nextPage = p; }
		this.resetPaginationBar (p)
    },
    goToPageDashboard() {
      this.$router.replace("/adminpagedashboard");
    },

	goToPage(router, id) {
		if (id > 0) {
			this.$router.push({ path: `/${router}/${id}` });
		} else {
			this.$router.push({ path: `/${router}` });
		}
	},
	updatePage(id) {
		this.$router.push({ path: `/adminupdatepage/${id}` });
	},

	showDeleteConfirmation(id, title) {
		this.isDeleting = true;
		this.idToDelete = id;
		this.titleForDeletion = title;
	},

	async deleteItem(id) {
		this.isDeleting = false;
		//this.$store.dispatch("page/deleteItem", id);
  		await api.deletePage(id)
		this.message = "Deletion is successfull";
		this.$router.push({ path: `/adminpagedashboard` });
	},
	cancelDeletion() {
		this.isDeleting = false;
	},

    async initialLoadItems () {
		this.items=[]

      this.loading = true
    	const payload = { id:this.$route.params.id, limit: this.recordPerPage, offset: 0 };

      const ps = await api.listPageInCategoryAdmin(payload)


		this.items=ps
      this.loading = false

      this.totalRecords = this.items.count;
      this.totalPages = Math.ceil(this.totalRecords / this.recordPerPage);

		this.totalDecades=Math.ceil(this.totalPages / 10);

		if (this.totalDecades > this.currentDecade) { this.showNextDecade=true }
		if (this.totalDecades==1) {
			this.lastButtonInCurrentPaginationBar=this.totalPages
			this.loadPaginationBar()
			this.paginationBar[0].className="page-item active"
		}
		this.showPaginationBar=this.totalPages>1 ? true : false;
    },

  loadPaginationBar (){
	this.paginationBar=[]
     for (let i = this.firstButtonInCurrentPaginationBar; i < this.lastButtonInCurrentPaginationBar+1; i++) {
       this.paginationBar.push({ page: i, className: "page-item" });
     }
  },

    async previousDecade() {
		this.currentDecade--
		this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
		this.lastButtonInCurrentPaginationBar=this.firstButtonInCurrentPaginationBar+9

		if (this.currentDecade == 1) {
			this.showPreviousDecade=false
			this.showNextDecade=true
		}
		else {
			//this.showPreviousDecade=false
			//this.showNextDecade=true
		}
		this.loadPaginationBar()
		this.resetPaginationBar (this.currentDecade*10)
		this.pagination(this.currentDecade*10)
	 },

    async nextDecade() {
		this.currentDecade++
		this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
		if (this.currentDecade == this.totalDecades) {
			this.lastButtonInCurrentPaginationBar=this.totalPages
		}
		if (this.currentDecade < this.totalDecades) {
			this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
			this.lastButtonInCurrentPaginationBar=this.firstButtonInCurrentPaginationBar+9
		}
		this.loadPaginationBar()
		this.resetPaginationBar (this.currentDecade*10-9)
		this.pagination(this.currentDecade*10-9)
	 },

	 resetPaginationBar (pageButton) {
		this.paginationBar.forEach((element, index)=>{
			element.className='page-item'
			if (element.page==pageButton) { element.className='page-item active' }
		});

		// Next-Previous Buttons
		if (this.currentPage == ((this.currentDecade*10)-9)) { this.showPreviousPage=false}
		else { this.showPreviousPage=true}
		if (this.currentPage == (this.currentDecade*10)) { this.showNextPage=false}
		else { this.showNextPage=true}
		if (this.currentPage ==this.totalPages ) {this.showNextPage=false}

		// Next-Previous Decade Buttons
		if (this.totalDecades > 1) { 
			if (this.currentDecade == 1) {this.showPreviousDecade=false;this.showNextDecade=true}
			if (this.currentDecade == this.totalDecades) {this.showPreviousDecade=true;this.showNextDecade=false}
			if (this.currentDecade > 1 && this.currentDecade < this.totalDecades) {this.showPreviousDecade=true;this.showNextDecade=true}
		}

	 },
    goToDashboard() {
      this.$router.replace("/dashboard");
    },

  }//end of methods
}
//0D6EFD
//0d6efd
</script>

<style scoped>
.move {
	width:40px;
	height:40px;
}
.pagination-container {
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
}
.flex-container {
	 display: flex;
  justify-content: center;
}
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}

.delete {
  background-color: #bb0000;
}
.id {
	color: #aaa
}

.page-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 48px;
	min-height: 48px;
	padding: 10px 10px;
	border: 0;
	border-radius: 6px;
	font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 1.rem;
	font-weight: 500;
	line-height: 1.71429;
	background-color: #022E42;
	color: #fff;
	transition: all 0.3s ease-in-out;
	margin:0.2rem;
	cursor:pointer;
}
.page-item.active .page-link {
    background-color: #bb0000;
}
.page-item:hover .page-link:hover {
    background-color: #990000;
	 color:#ffffff
}
.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.back-go-dasboard {
	 background-color:#880000;
}
.category {
	 background-color:#f7f7f7;
	 color:#777;
	 font-size:1.5rem;
}
.admin-list-title {
	 font-size:1.2rem;
}

</style>
