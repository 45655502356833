import axios from 'axios';
import store from '../src/store/index';

export default {
	//PAGES
	
	async listPageInHomePage (lg) {
		//const lg=store.getters.lg;
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page/homepagelist?lg=${lg}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async listPageInSearch (payload) {
		console.log("MIÉRT")
		console.log(payload)
		const lg=store.getters.lg;
		console.log(lg)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		//const url = `${API_URL}/page/category/${payload.id}?lg=${lg}&offset=${payload.offset}&limit=${payload.limit}`;
		const url = `${API_URL}/page/searchresult/?search=${payload.search}&lg=${lg}&offset=${payload.offset}&limit=${payload.limit}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async listPageInCategory (payload) {
		console.log("MIÉRT")
		console.log(payload)
		const lg=store.getters.lg;
		console.log(lg)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page/category/${payload.id}?lg=${lg}&offset=${payload.offset}&limit=${payload.limit}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async listPageInCategoryAdmin (payload) {
		console.log("MIÉRT")
		console.log(payload)
		const lg=store.getters.lg;
		console.log(lg)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page/categoryadmin/${payload.id}?lg=${lg}&offset=${payload.offset}&limit=${payload.limit}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async getPageCategories () {
		const lg=store.getters.lg;
		const API_URL = process.env.VUE_APP_API_URL;
		const token=store.getters.token;
		const userRealId=store.getters.userRealId;
		const url = `${API_URL}/pagecategory/user/${userRealId}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }, params:{lg:lg} };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async getPages () {
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async getPageAdmin (id) {
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const lg=store.getters.lg;
		const url = `${API_URL}/page/admin/${id}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }};
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async getPage (id) {
		//console.log("III")
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const lg=store.getters.lg;
		const url = `${API_URL}/page/${id}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }, params:{lg:lg}};
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},


	async getPageCategoryPath (id) {
		const lg=store.getters.lg;
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/pagecategory/path/${id}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }, params:{lg:lg} };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	//Create Record
	async createPage (payload) {
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };

		try {
			let response = await axios.post(url, payload, config);
			let idCreated=response.data.id;
        // UPLOAD FILES
		//console.log("xxx"); console.log(payload); console.log("xxx");
		//uploadFiles(payload[1]);
        // /UPLOAD FILES


			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	// /Create Record
	// Delete Record 
	async deletePage (payload) {
		
		//console.log("DELETING ...");
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const config = { headers: { 'Authorization': `Basic ${token}` } };

			let url = `${API_URL}/page/${payload}`;
			try {
			//	console.log("i: "+i);
				let response = await axios.delete(url, config);
				return response.data;
			//	return 1;	
			} catch (error) {
				if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
				else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
				else { console.log('Error', error.message);}
			}
	},

	// Delete Record 
	// Delete Record 
otto (){
//console.log("OTTO");
},
	
	// Upload Files
	async uploadFiles (filePayload,pageId) {
		//console.log("uploadFiles CALLED");
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
      const url = `${API_URL}/filepage/${pageId}/upload-files`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.post(url, filePayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	// /Upload Files


	//Update Record
	async updatePage (payload) {

		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/page`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		const dataPayload = payload;

		try {
			let response = await axios.put(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async deleteFiles (payload) {

		//console.log("DELETING ...");
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		//console.log("payload: ");
		//console.log(payload);
		//console.log("payload: ");
		//let n=Object.keys(payload).length;
		//console.log("n: "+n);
		//n=10;
		//console.log("n: "+n);

		//for (let j = 0; j < n ; j++) { console.log("j: "+j); }
			let url = `${API_URL}/filepage/delete-file/${payload}`;
			try {
			//	console.log("i: "+i);
				let response = await axios.delete(url, config);
				return response.data;
			//	return 1;	
			} catch (error) {
				if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
				else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
				else { console.log('Error', error.message);}
			}

		/*
		for (let i = 0; i < payload.length; i++) {
			const url = `${API_URL}/files/delete-file/${payload[i]}`;
			const config = { headers: { 'Authorization': `Basic ${context.rootGetters.token}` } };
			axios.delete(url, config)
				.then(response => {
					//response.data;
					//context.dispatch('loadMyItems')
					console.log("File deleted"); 
					console.log(response.data); 

				})
				.catch((error) => {
					if (error.response) {
						context.commit('setApiError', { error: true, message: error.response.data.error });

					} else if (error.request) {
						context.commit('setApiError', { error: true, message: 'Server error. Please try later!' });
					} else {
						console.log('Error', error.message);
					}
				})
		}
		*/

				},

	// USERS

	async getUsers (payload) {
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		//const url = `${API_URL}/auth`;

		const url = `${API_URL}/auth/?offset=${payload.offset}&limit=${payload.limit}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async deleteUser (payload) {
		
		//console.log("DELETING ...");
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const config = { headers: { 'Authorization': `Basic ${token}` } };

		//console.log("payload: ");
		//console.log(payload);
		//console.log("payload: ");

			let url = `${API_URL}/auth/${payload}`;
			try {
			//	console.log("i: "+i);
				let response = await axios.delete(url, config);
				return response.data;
			//	return 1;	
			} catch (error) {
				if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
				else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
				else { console.log('Error', error.message);}
			}
	},

	async getUserAdmin (id) {

		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const lg=store.getters.lg;
		const url = `${API_URL}/auth/${id}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }};
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async updateUser (payload) {

		//console.log("updateUser Frontend api")
		//console.log(payload)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/auth`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		const dataPayload = payload;

		try {
			let response = await axios.put(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async forgotPassword (payload) {

		//console.log("Forgot Password api")
		//console.log(payload)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/auth/forgotpassword`;
		const config = { headers: { } };
		const dataPayload = payload;

		try {
			let response = await axios.post(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async resetPassword (payload) {

		//console.log("Forgot Password api")
		//console.log(payload)
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/auth/resetpassword`;
		const config = { headers: { } };
		const dataPayload = payload;

		try {
			let response = await axios.put(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	// USERS
	
	// PAGE CATEGORY
	
	async getPageCategoryAdmin (id) {
		//console.log("PPPP")
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const lg=store.getters.lg;
		const url = `${API_URL}/pagecategory/admin/${id}`;
		const config = { headers: { 'Authorization': `Basic ${token}` }};
		try {
			let response = await axios.get(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},

	async createPageCategory (payload) {

		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/pagecategory`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		const dataPayload = payload;

		try {
			let response = await axios.post(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async updatePageCategory (payload) {

		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		const url = `${API_URL}/pagecategory`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		const dataPayload = payload;

		try {
			let response = await axios.put(url, dataPayload, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	},
	async deletePageCategory (payload) {
		let API_URL = process.env.VUE_APP_API_URL;
		let token=store.getters.token;
		let url = `${API_URL}/pagecategory/${payload}`;
		const config = { headers: { 'Authorization': `Basic ${token}` } };
		const dataPayload = payload;

		try {
			let response = await axios.delete(url, config);
			return response.data;
		} catch (error) {
			if (error.response) { let apiError={error: true, message: error.response.data.error }; console.log(apiError); } 
			else if (error.request) { let apiError={ error: true, message: 'Server error. Please try later!' }; console.log(apiError); } 
			else { console.log('Error', error.message);}
		}
	}

	// / PAGE CATEGORY

}
