<template>
  <section>
    <base-card>
      <h2>Page not found</h2>
      <h5>
        This page could not be found - 404 Error
      </h5>
    </base-card>
  </section>
</template>
