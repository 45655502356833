import { createStore } from 'vuex';
import pageModule from './modules/page.js';
import authModule from './modules/auth/index.js';

const store = createStore({
  modules: {
    page: pageModule,
    auth: authModule
  }
});

export default store;
