<template>
  <div>
    <section class="section section-sm">
      <div class="container">
        <h1>User Dashboard</h1>
        <div class="row">
          <div class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
              <base-button type="button" @click="goToSignup()"
                >Register New User</base-button
              >
          </div>
          <div class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button" @click="goToListUsers">List Users</base-button>
          </div>
        </div>
  </div>
    </section>
  </div>
</template>

<script>
import api from "../../../api/client";

export default {

  components: {
  },
  data() {
    return {
      items: [],
		loading: false, 
    };
  },
  mounted() {
   this.$store.commit("page/setShowCarusel",false);
  },

  created() {

  },
  computed: {
  },

  methods: {

    goToListUsers() {
      this.$router.replace("adminlistuser");
    },
    goToSignup() {
      this.$store.dispatch('logout');
      this.$router.replace("signup");
    },
  },
};
</script>

<style scoped>
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,

.delete {
  background-color: #bb0000;
}
.delete:hover {
  background-color: #ff0000;
}
.delete_title {
  text-align: center;
}
.id {
  color: gray;
}
.centered {
  text-align: center;
  display: block;
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.passivePageCategory {
  font-weight: normal;
  color: #000000;
}
</style>
