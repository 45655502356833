const capFirstLetter = (val) => val.charAt(0).toUpperCase() + val.slice(1);
const img2xUrl = (val) => `${val.replace(/(\.[\w\d_-]+)$/i, '@2x$1')} 2x`;
const convertDateToLg = (val, lg) => {
	let dateLg=""
	let year=val.substr(0,4)
	let month=val.substr(5,2)
	let day=val.substr(8,2)
	if (lg === "cp") { dateLg=day+"."+month+"."+year }
	if (lg === "sr") { dateLg=day+"."+month+"."+year }
	if (lg === "sk") { dateLg=day+"."+month+"."+year }
	if (lg === "ro") { dateLg=day+"."+month+"."+year }
	if (lg === "hu") { dateLg=year+"."+month+"."+day }
	if (lg === "en") { dateLg=day+"."+month+"."+year }
	return dateLg 
}

const cyrToLat = (aText) => {
	aText = aText.replace(/љ/g,'lj');
	aText = aText.replace(/Љ/g,'Lj');
                                                       
	aText = aText.replace(/њ/g,'nj');
	aText = aText.replace(/Њ/g,'Nj');
                                                       
	aText = aText.replace(/џ/g,'dž');
	aText = aText.replace(/Џ/g,'Dž');

	aText = aText.replace(/а/g,'a');
	aText = aText.replace(/б/g,'b');
	aText = aText.replace(/ц/g,'c');
	aText = aText.replace(/ч/g,'č');
	aText = aText.replace(/ћ/g,'ć');
	aText = aText.replace(/д/g,'d');
	aText = aText.replace(/ђ/g,'đ');
	aText = aText.replace(/е/g,'e');
	aText = aText.replace(/ф/g,'f');
	aText = aText.replace(/г/g,'g');
	aText = aText.replace(/х/g,'h');
	aText = aText.replace(/и/g,'i');
	aText = aText.replace(/ј/g,'j');
	aText = aText.replace(/к/g,'k');
	aText = aText.replace(/л/g,'l');
	aText = aText.replace(/м/g,'m');
	aText = aText.replace(/н/g,'n');
	aText = aText.replace(/о/g,'o');
	aText = aText.replace(/п/g,'p');
	aText = aText.replace(/р/g,'r');
	aText = aText.replace(/с/g,'s');
	aText = aText.replace(/ш/g,'š');
	aText = aText.replace(/т/g,'t');
	aText = aText.replace(/у/g,'u');
	aText = aText.replace(/в/g,'v');
	aText = aText.replace(/з/g,'z');
	aText = aText.replace(/ж/g,'ž');
                                                      
	aText = aText.replace(/А/g,'A');
	aText = aText.replace(/Б/g,'B');
	aText = aText.replace(/Ц/g,'C');
	aText = aText.replace(/Ч/g,'Č');
	aText = aText.replace(/Ћ/g,'Ć');
	aText = aText.replace(/Д/g,'D');
	aText = aText.replace(/Ђ/g,'Đ');
	aText = aText.replace(/Е/g,'E');
	aText = aText.replace(/Ф/g,'F');
	aText = aText.replace(/Г/g,'G');
	aText = aText.replace(/Х/g,'H');
	aText = aText.replace(/И/g,'I');
	aText = aText.replace(/Ј/g,'J');
	aText = aText.replace(/К/g,'K');
	aText = aText.replace(/Л/g,'L');
	aText = aText.replace(/М/g,'M');
	aText = aText.replace(/Н/g,'N');
	aText = aText.replace(/О/g,'O');
	aText = aText.replace(/П/g,'P');
	aText = aText.replace(/Р/g,'R');
	aText = aText.replace(/С/g,'S');
	aText = aText.replace(/Ш/g,'Š');
	aText = aText.replace(/Т/g,'T');
	aText = aText.replace(/У/g,'U');
	aText = aText.replace(/В/g,'V');
	aText = aText.replace(/З/g,'Z');
	aText = aText.replace(/Ж/g,'Ž');

	return aText;
}

export { capFirstLetter, img2xUrl, convertDateToLg,cyrToLat };
