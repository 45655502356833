<template>
<section>
<div class="page-category-tree">
<div :class="{bold: isFolder}" id="tr" > 
<span :id="item.id" class="pageCategory">
<div  :value="item.id" > {{ item.title }}</div> 
</span>
<div :id="containerId">
   <base-button type="button" @click="populatePageCategoryForm(item.id)" class="add-new-btn">Update</base-button>
   <base-button type="button" @click="clearPageCategoryForm(item.id)" class="add-new-btn">Add New Subcategory</base-button>
   <base-button type="button" @click="deletePageCategoryForm(item.id)" class="delete-btn">Delete</base-button>
</div>
</div>

<!-- Delete Form -->
			<div v-if="showDelete" class="form-control update-form">
			<h4>Are You sure to delete?</h4>
			<h3>{{formTitle}} </h3>
        <div class="d-flex justify-content-center">
          <base-button type="button" @click="submitForm">Delete</base-button>
          <base-button type="button" @click="cancelDeleteForm" class="cancel">Cancel</base-button>
        </div>

			<span v-if="saved" class="success">
          <img alt="Saved successfuly" src="../../assets/success.svg" width="35" hspace="10" vspace="10" style="vertical-align: middle" />
			Saved successfuly
			</span>
        </div>
<!-- /Delete Form -->

<!-- Edit Form -->
			<div v-if="showEdit" class="form-control update-form">
			<h3>{{formTitle}} </h3>
        <div>
          <label for="password">[{{item.id}}] Pareint ID: </label>
          <input type="text" id="titleSR" v-model.trim="parentId" />
        </div>
        <div>
          <label for="password">Title SR: </label>
          <input type="text" id="titleSR" v-model.trim="titleSR" />
        </div>
        <div>
          <label for="password">Title SK: </label>
          <input type="text" id="titleSK" v-model.trim="titleSK" />
        </div>
        <div>
          <label for="password">Title RO: </label>
          <input type="text" id="titleRO" v-model.trim="titleRO" />
        </div>
        <div>
          <label for="password">Title HU: </label>
          <input type="text" id="titleHU" v-model.trim="titleHU" />
        </div>
        <div>
          <label for="password">Title EN: </label>
          <input type="text" id="titleEN" v-model.trim="titleEN" />
        </div>
        <div>
          <label for="password">Show Page Date: </label>
			<input type="checkbox" id="showPageDate" v-model="showPageDate">
        </div>
        <div>
          <label for="password">Template Type: </label>
          <input type="text" id="templateType" v-model.trim="templateType" />
        </div>
        <div>
          <label for="password">Order: </label>
          <input type="text" id="order" v-model.trim="order" />
        </div>

        <div class="d-flex justify-content-center">
          <base-button type="button" @click="submitForm">Save</base-button>
          <base-button type="button" @click="cancelForm" class="cancel">Cancel</base-button>
        </div>

			<span v-if="saved" class="success">
          <img alt="Saved successfuly" src="../../assets/success.svg" width="35" hspace="10" vspace="10" style="vertical-align: middle" />
			Saved successfuly
			</span>
        </div>
<!-- /Edit Form -->

<ul v-show="isOpen" v-if="isFolder">
<tree-item-change class="item" v-for="(child, index) in item.children" :key="index" :item="child">
</tree-item-change>
</ul>
		
</div>
</section>
</template>

<script>
import api from "../../../api/client";

export default {
	props: ['item'],
  data() {
    return {
		currentId: null,
		apiType: "",
      isOpen: true,
		menuClass: "passivePageCategory",
      itemsTemp: [],
      message: "",
		checkedPageCategoryIds:[],
		showEdit: false,
		showDelete: false,
		parentId: null,
		titleSR: "", 
		titleSK: "", 
		titleRO: "", 
		titleHU: "", 
		titleEN: "", 
		showPageDate: false,
		templateType: "", 
		order: "", 
		formTitle:"",
		saved: false 
    };
  },
  mounted() {
		//document.getElementById("mt-0").innerHTML = "";
		document.getElementById("mt-0").style.display = 'none';
  },
  computed: {
     isFolder: function() { return this.item.children && this.item.children.length;},
     containerId: function() { 
			return typeof(this.item.id) != "undefined" ? "mt-"+this.item.id : "mt-0";
	  },
  },

  created() {
	 	const pageCategoryIds=this.$store.getters['page/pageCategoryIds']

		for (const element of pageCategoryIds) {
		  this.checkedPageCategoryIds.push(element);
		}
  },

  methods: {

	 clearPageCategorySelection() {
		for (let i = 0; i < 100; i++) { 
			if  (document.getElementById(i) != null) {
  				document.getElementById(i).classList.remove("activePageCategory");
			}
		}
	 },

   presetPageCategory () { 
		const pageCategoryIds=this.$store.getters['page/pageCategoryIds'];
		this.checkedPageCategoryIds=pageCategoryIds

	},
   toggle () { 
		if (this.isFolder) { this.isOpen = !this.isOpen;}
		this.presetPageCategory();
   },

   async populatePageCategoryForm (id) { 
		this.showEdit=true
		this.apiType="put"
		this.formTitle=`Updating page categori of [ID: ${id}]`
		
      const itm = await api.getPageCategoryAdmin(id)
		this.parentId=itm.parentId
		this.titleSR=itm.titleSR
		this.titleSK=itm.titleSK
		this.titleRO=itm.titleRO
		this.titleHU=itm.titleHU
		this.titleEN=itm.titleEN
		this.templateType=itm.templateType
		this.order=itm.order
		this.showPageDate=itm.showPageDate
   },

    clearPageCategoryForm (id) { 
		this.showEdit=true
		this.apiType="post"
		this.formTitle=`Creat new page categori for parent [ID: ${id}]`
		this.parentId=id
		this.titleSR=""
		this.titleSK=""
		this.titleRO=""
		this.titleHU=""
		this.titleEN=""
		this.templateType=""
		this.order=""
		this.showPageDate=false
   },

   deletePageCategoryForm (id) { 
		this.showDelete=true
		this.apiType="delete"
		this.formTitle=`[ID: ${id}] ${this.item.title} `
	},
	cancelForm () {
		this.showEdit=false
	},
	cancelDeleteForm () {
		this.showDelete=false
	},

    async submitForm() {
		 console.log("SSS")

      this.isLoading = true;
      let actionPayload = {
        parentId: this.parentId,
        titleSR: this.titleSR,
        titleSK: this.titleSK,
        titleRO: this.titleRO,
        titleHU: this.titleHU,
        titleEN: this.titleEN,
        showPageDate: this.showPageDate,
        templateType: this.templateType,
        order: this.order,
      };
      try {

			if (this.apiType == "put") {
				actionPayload.id=this.item.id
      		this.apiResponse = await api.updatePageCategory(actionPayload)
			}
			if (this.apiType == "post") {
      		this.apiResponse = await api.createPageCategory(actionPayload)
			}
			if (this.apiType == "delete") {
      		this.apiResponse = await api.deletePageCategory(this.item.id)
			}

			this.saved=true
			setTimeout(()=>{this.$router.replace("dashboard")}, 1000);
      	
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
      }
      this.error = this.$store.getters.apiError;

      this.isLoading = false;
			console.log("SAVED");

	 }
  },
};
</script>

<style scoped>
.page-category-tree {
	margin: 5px;
}
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.pageCategory {
  font-weight: normal;
  font-size: 1.2rem;
  color: #000000;
}
.pageCategory:hover {
  color: #ee0000;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}

.cancel {
background-color: #25809c;
font-size: 0.9rem;
}
.update-form {
    background-color: #d8f0f2;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid;
    border-radius: 7px;
}
INPUT {
margin: 5px;
}
.add-new-btn {
	font-size: 0.7rem;
	padding: 3px;
	width:130px;
}
.delete-btn {
	font-size: 0.7rem;
	padding: 3px;
	width:70px;
	background-color:#ff0000;
}
</style>
