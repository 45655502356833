export default {
    setUser(state, payload) {
        state.token = payload.token;
        state.userEmail = payload.userEmail;
        state.userPagePrivilege = payload.userPagePrivilege;
        state.userModulePrivilege = payload.userModulePrivilege;
        state.userRealId = payload.userRealId;
        state.didAutoLogout = false;
    },
    setLg(state, payload) {
        state.lg = payload;
    },
    setAutoLogout(state) {
        state.didAutoLogout = true;
    },
    setApiSuccess(state, payload) {
        state.apiSuccess = payload;
    },
    setApiError(state, payload) {
        state.apiError = payload;
    },
    setApiErrorMessage(state, payload) {
        state.apiErrorMessage = payload;
    }
};
