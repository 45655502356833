<template>
<div>
<base-dialog :show="isUploading" title="Uploading Files..." fixed>
<base-spinner>Pleas wait to finish.</base-spinner>
</base-dialog>
<base-card>
<div class="container">
<h2>{{ appMessages.pageUpdate.[lgs] }}</h2>
<h3>ID: [{{item.id}}]</h3>


<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 	<base-button type="button" @click="updateItem">{{ appMessages.save.[lg] }}</base-button>
 	<base-button type="button" @click="goToPage('showpage', item.id)">{{ appMessages.showPage.[lg] }}</base-button>
 	<base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->

<span v-if="saved" class="success">
  <img alt="{{ appMessages.saveSuccess.[lg] }}" src="../../assets/success.svg" width="35" hspace="10" vspace="10" style="vertical-align: middle" />
{{ appMessages.saveSuccess.[lg] }}	
</span>
<div >
  <div >

<!--PAGE CATEGORY -->
<label for="pageCategory">{{appMessages.category.[lg]}}</label>
<div class="current-page-category">
<ul id="example-1">
  <li v-for="item in pageCategoryPath" @click="showPagesInCategory(item.id)" class="page-category-list">
     {{ item.title }}
  </li>
</ul>
</div>
<ul id="page-category-tree" class="page-category-tree">
   <tree-item :item=treeDataDB ></tree-item>
</ul>
<!--END PAGE CATEGORY -->

<!--PAGE DATE -->
<div class="flex-container">
<label for="pageDate">{{appMessages.pageDate.[lg]}}: </label>
<div class="flex-item"><input type="text" id="pageDate" v-model=item.pageDate /></div>
</div>
<!--ENG PAGE DATE -->

<!--FLAG BAR -->
<div class="flex-lg-container">
<div class="flex-lg-item" v-bind:class="{ ooo: flagAdmin.cp }">
<img  @click="activateLg('cp')" src="/assets/images/icons/flags/cp.svg" alt="Srpski" title="Srpski" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.sk }">
<img @click="activateLg('sk')" src="/assets/images/icons/flags/sk.svg" alt="Slovenský" title="Slovenský" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.ro }">
<img  @click="activateLg('ro')" src="/assets/images/icons/flags/ro.svg" alt="Română" title="Română" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.hu }">
<img  @click="activateLg('hu')" src="/assets/images/icons/flags/hu.svg" alt="Magyar" title="Magyar" height="30" width="50"/>
</div>

<div class="flex-lg-item"  v-bind:class="{ ooo: flagAdmin.en }">
<img  @click="activateLg('en')" src="/assets/images/icons/flags/en.svg" alt="English" title="English" height="30" width="50"/>
</div>
</div>
<!--END FLAG BAR -->

<!--TITLE & CONTENT -->
<div class="lg-box">
        <div  v-if="flagAdmin.cp" class="flex-container">
          <label class="page-title">{{appMessages.pageTitle.[lg]}}: </label>
          <div class="flex-item"><input class="page-title" type="text" id="titleCP" v-model=item.titleCP /></div>
        </div>

        <div v-if="flagAdmin.sk" class="flex-container">
          <label class="page-title">{{appMessages.pageTitle.[lg]}}: </label>
          <div class="flex-item"><input class="page-title" type="text" id="titleSK" v-model=item.titleSK /></div>
        </div>

        <div  v-if="flagAdmin.ro" class="flex-container">
          <label class="page-title">{{appMessages.pageTitle.[lg]}}: </label>
          <div class="flex-item"><input class="page-title" type="text" id="titleRO" v-model=item.titleRO /></div>
        </div>

        <div  v-if="flagAdmin.hu" class="flex-container">
          <label class="page-title"> {{appMessages.pageTitle.[lg]}}:	</label>
          <div class="flex-item"><input class="page-title" type="text" id="titleHU" v-model=item.titleHU /></div>
        </div>

        <div  v-if="flagAdmin.en" class="flex-container">
          <label class="page-title">{{appMessages.pageTitle.[lg]}}: </label>
         <div class="flex-item"> <input class="page-title" type="text" id="titleEN" v-model=item.titleEN /></div>
        </div>

        <div  v-if="flagAdmin.cp" class="form-control">
         <label for="content CP">{{appMessages.pageContent.[lg]}}: </label>
			<editor v-bind:api-key=apiKey :init=initTinyMCE v-model="item.contentCP" />
        </div>

        <div  v-if="flagAdmin.sk" class="form-control">
         <label for="content SK">{{appMessages.pageContent.[lg]}}: </label>
			<editor v-bind:api-key=apiKey :init=initTinyMCE v-model="item.contentSK" />
        </div>

        <div  v-if="flagAdmin.ro" class="form-control">
         <label for="content RO">{{appMessages.pageContent.[lg]}}: </label>
			<editor v-bind:api-key=apiKey :init=initTinyMCE v-model="item.contentRO" />
        </div>

        <div  v-if="flagAdmin.hu" class="form-control">
         <label for="content HU">{{appMessages.pageContent.[lg]}}: </label>
			<editor v-bind:api-key=apiKey :init=initTinyMCE v-model="item.contentHU" />
        </div>

        <div  v-if="flagAdmin.en" class="form-control">
         <label for="content EN">{{appMessages.pageContent.[lg]}}: </label>
			<editor v-bind:api-key=apiKey :init=initTinyMCE v-model="item.contentEN" />
        </div>
</div>
<!--END TITLE & CONTENT -->

<!--PAGE ORDER -->
<div class="flex-container">
  <label for="pageOrder">{{ appMessages.pageOrder.[lg] }}: </label>

   <div class="flex-item">
     <input type="number" id="pageOrder" v-model=item.pageOrder />
   </div>
</div>
<!--END PAGE ORDER -->

<!--FRONTPAGE & ACTIVE -->
        <div class="flex-container">
        <div class="form-control">
          <label for="active">{{ appMessages.frontPage.[lg] }}:</label>
			CP <input type="checkbox" id="checkbox" v-model="item.frontPageCP">
			SK <input type="checkbox" id="checkbox" v-model="item.frontPageSK">
			RO <input type="checkbox" id="checkbox" v-model="item.frontPageRO">
			HU <input type="checkbox" id="checkbox" v-model="item.frontPageHU">
			EN <input type="checkbox" id="checkbox" v-model="item.frontPageEN">
        </div>

        <div class="form-control">
          <label for="active">{{ appMessages.active.[lg] }}:</label>
			CP <input type="checkbox" id="checkbox" v-model="item.activeCP">
			SK <input type="checkbox" id="checkbox" v-model="item.activeSK">
			RO <input type="checkbox" id="checkbox" v-model="item.activeRO">
			HU <input type="checkbox" id="checkbox" v-model="item.activeHU">
			EN <input type="checkbox" id="checkbox" v-model="item.activeEN">
        </div>
        </div>
<!--END FRONTPAGE & ACTIVE -->

<span v-if="saved" class="success">
<img alt="{{ appMessages.saveSuccess.[lg] }}" src="../../assets/success.svg" width="35" hspace="10" vspace="10" style="vertical-align: middle" />
{{ appMessages.saveSuccess.[lg] }}	
</span>
</div>
</div>

<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 	<base-button type="button" @click="updateItem">{{ appMessages.save.[lg] }}</base-button>
 	<base-button type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 	<base-button type="button" @click="goToPage('showpage', item.id)">{{ appMessages.showPage.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->

<!--  FILE UPLOAD BOX-->
<div class="file-upload">
          <!--  FILE UPLOAD -->
          <div v-show="showFileUpload">
            <h2>{{ appMessages.fileUpload.[lg] }}</h2>
						<span v-html="appMessages.fileType.[lg]"></span>
            <ul>
              <li v-for="item in selectedFilesDescriptions" :key="item">
                File name: {{ item.name }}, size: {{ item.size }} Bytes
              </li>
            </ul>
            <div class="d-flex justify-content-center mt-3">
              <input
                style="display: none"
                type="file"
                @change="onFileSelected"
                ref="fileInput"
                multiple
              />
  <base-button class="upload-files" type="button" @click="$refs.fileInput.click()" >{{ appMessages.selectFilesToUpload.[lg] }}</base-button>
            </div>

            </div>
          <!--  / FILE UPLOAD -->

<!-- DOCUMENT SET -->
<section>
<div class="pic_gal mt-4 mb-3">
<h3>{{ appMessages.documentsRelatedToThisPage.[lg] }}:</h3>
<div v-if="documents.length > 0" class="row row-x-10 justify-content-center mt-3" data-lightgallery="group">

<div class="flex-container">
<div v-for="(item, index) in documents" :key="item.id" class="flex-items-attachments">
<a v-bind:href="`${apiUrl}/${item.path}`" target="_blank" title="Show or download this document">
<img width="50" v-bind:src="`${item.icon}`" ></a>
<div class="file-name" @click="copyToClipboard(item.fileName)" title="Copy path to clipboard" alt="Copy path to clipboard">{{item.fileName}}</div>
<input type="checkbox" class="delete-files-check" v-bind:value="item.id" id="checkbox" v-model="checkedFiles">
</div>

</div>
</div>
<h5 v-else>{{ appMessages.noDocuments.[lg] }}</h5>
</div>
</section>
<!-- END DOCUMENTS SET -->

<!-- IMAGE SET -->
<section>
<div class="pic_gal mt-5 mb-3">
<h3>{{ appMessages.imagesRelatedToThisPage.[lg] }}:</h3>
<div v-if="pictures.length > 0" class="row row-x-10 justify-content-center mt-3" data-lightgallery="group">

<div class="flex-container">
<div v-for="(item, index) in pictures" :key="item.id" class="flex-items-attachments">

<img class="thumbnail-minimal-image" v-bind:src="`${apiUrl}/${item.path}`" alt="" />

<div class="flex-items-attachemnts">
{{item.fileName}}
</div>
<div class="flex-items-attachemnts">
<input type="checkbox" class="delete-files-check" v-bind:value="item.id" id="checkbox" v-model="checkedFiles">
</div>
</div>
</div>
</div>

<h5 v-else>{{ appMessages.noDocuments.[lg] }}</h5>
</div>
</section>
<!-- END IMAGE SET -->

<base-button type="button" @click="deleteFiles" class="delete-files">{{ appMessages.deleteSelectedFiles.[lg] }}</base-button>

<p v-show="apiError.error">
<img alt="Error" src="../../assets/error.svg" width=35 hspace=4 style="vertical-align:middle">
{{apiError.message}}
</p>
</div>
<!--  END FILE UPLOAD BOX-->
</div>
</base-card>
</div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import TreeItem from './TreeItem.vue';
import Editor from '@tinymce/tinymce-vue'
import { convertDateToLg, cyrToLat } from "../../helpers/index.js";

let images;
let net;
let reader;
export default {
  components: {
    TreeItem,
    'editor': Editor
  },
  data() {
    return {
		appMessages:appMessages,
		 cpcidsData:[1,3],

			documents:[],	
			pictures:[],	
			selectedFiles:[],	
			selectedFilesDescriptions:[],
      showFileUpload: true,
			isUploading: false,
			saved:false,
			checkedFiles:[],
			apiResponse:"",
			item:"",
	      apiUrl: "",
			id: null,
			filePayload:null, 
      	items: [],
			loading: false, 
			tree: "",
			treeDataDB: { title: "Please Select Category ", id:0, children:[] },
			pageCategoryPath:[],
			checked: true, 
			flagAdmin: {cp: true, sk: false, ro: false, hu: false, en: false },
			lg:"cp",
			isOoo:true,
      apiKey: "wria4sk0ahd4h4l9zft1wwffywo7caht79i0cp190lize02r",
      initTinyMCE: {
      	menubar: false,
      	plugins: 'lists link image emoticons code fullscreen',
	      toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | link image emoticons code fullscreen',
        image_list: [ ],
      }
    };
  },
	computed:{
		apiError () {return this.$store.getters['page/apiError'];},
		 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
		 }

	},
	mounted() {
   	this.$store.commit("page/setShowCarusel",false);
	},
  created() {
    this.loadItem(this.$route.params.id)
    this.loadPageCategories()
    this.apiUrl = process.env.VUE_APP_API_URL;
    this.frontendUrl = process.env.VUE_APP_SITE_URL;
  },
	
	methods: {
	  async loadPageCategories () {

		this.$store.commit("page/deletePageCategoryIds")
		  this.loading = true
		  this.items = await api.getPageCategories()
		  this.loading = false

			this.tree=this.items;
			this.convertToJSONTree(this.items)
			this.treeDataDB.children=this.tree;

	  },
	  convertToJSONTree  (data)  {
		  const tree = data.map(e => ({...e}))
			  .sort((a, b) => a.id - b.id)
			  .reduce((a, e) => {
					  a[e.id] = a[e.id] || e;
					  a[e.parentId] = a[e.parentId] || {};
					  const parent = a[e.parentId];
					  parent.children = parent.children || [];
					  parent.children.push(e);
					  return a;
					  }, {})
		  ;
		  this.tree= Object.values(tree) .find(e => e.id === undefined).children;
	  },
	 //Page Category Tree

    async storeFiles(event) {
      let err;
      const imageTypes = ["image/jpeg", "image/jpg", "image/png"];
      event.target.files.forEach((file) => {
        if (!imageTypes.includes(file.type)) err = "Extensions is not enabled";
      });
      if (err) {
        console.log(err);
        return;
      }
      images = event.target.files;
    },
    async loadItem (id) {
      this.loading = true
      this.item = await api.getPageAdmin(id)
		console.log(this.item.pagePageCategoryRelations[0].pageCategoryId)

		for (const element of this.item.pagePageCategoryRelations) {
			  console.log("e: "+element.pageCategoryId);
				this.$store.commit("page/setPageCategoryIds",element.pageCategoryId)
		}

		//console.log(this.$store.getters['page/pageCategoryIds'])

		let pathJSON=""
		let ppp=[]
		let title=""
		
		for (const element of this.$store.getters['page/pageCategoryIds']) {
			title=await api.getPageCategoryPath(element);
			pathJSON={"id":element, "title": title.path}
			this.pageCategoryPath.push(pathJSON)
		}
		console.log(this.pageCategoryPath)

      //this.pageCategoryPath = await api.getPageCategoryPath(this.item.pageCategoryIds[0])
      this.loading = false
		// /Documents

		let iconFile="";
		let fileObject=this.item.filePages;
		let documents=[];
		let pictures=[];
		let documentsFileFormats=[
			'application/zip',
			'application/pdf',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		];
		for (var key of Object.keys(fileObject)) {
			//if (p[key].format=="application/pdf") {
			if (documentsFileFormats.includes(fileObject[key].format)) {

				switch (fileObject[key].format) {
					case 'application/zip': iconFile="/assets/icons/zip.svg"; break;
					case 'application/pdf': iconFile="/assets/icons/pdf.svg"; break;
					case 'application/msword': iconFile="/assets/icons/doc.svg"; break;
					case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': iconFile="/assets/icons/doc.svg"; break;
					case 'application/vnd.ms-excel': iconFile="/assets/icons/xls.svg"; break;
					case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iconFile="/assets/icons/xls.svg"; break;
					default: iconFile ="";
				}
				documents.push(JSON.parse(`{"id":${fileObject[key].id}, "path":"${fileObject[key].path}","fileName":"${(fileObject[key].path).substr(17)}", "icon": "${iconFile}"}`));


			}
		}
		this.documents=documents;
		// /Documents
		// Pictures 
		let picturesFileFormats=[ 'image/jpeg', 'image/png' ];
		for (var key of Object.keys(fileObject)) {
			if (picturesFileFormats.includes(fileObject[key].format)) {
				pictures.push(JSON.parse(`{"id":${fileObject[key].id}, "path":"${fileObject[key].path}","fileName":"${(fileObject[key].path).substr(17)}"}`));
			}

		this.pictures=pictures;
		}
		//tinyMCE

			let currentImage ="" 
			let currentImageObj ="" 
			let picUrl=""
			for (let i = 0; i < this.pictures.length; i++) {	 
				picUrl=this.apiUrl+"/"+this.pictures[i].path
				currentImage=`{"title": "Image ${i+1}", "value": "${picUrl}"}`
				currentImageObj=JSON.parse(currentImage);
				this.initTinyMCE.image_list.push(currentImageObj);
			}

		//tinyMCE
		// /Pictures

		//this.$store.commit("page/setPageCategoryIds",this.item.pageCategoryIds);
    }, // /end of loaditem()
    async updateItem() {

		const pageCategoryIds=this.$store.getters['page/pageCategoryIds']

      const payload = { 
			id: this.item.id, 
			pageCategoryIds: pageCategoryIds, 
			pageDate: this.item.pageDate, 
			titleCP: this.item.titleCP, 
			titleSR: cyrToLat(this.item.titleCP), 
			titleSK: this.item.titleSK, 
			titleRO: this.item.titleRO, 
			titleHU: this.item.titleHU, 
			titleEN: this.item.titleEN, 
			contentCP: this.item.contentCP, 
			contentSR: cyrToLat(this.item.contentCP), 
			contentSK: this.item.contentSK, 
			contentRO: this.item.contentRO, 
			contentHU: this.item.contentHU, 
			contentEN: this.item.contentEN, 
			pageOrder: this.item.pageOrder, 
			frontPageCP: this.item.frontPageCP, 
			frontPageSR: this.item.frontPageCP, 
			frontPageSK: this.item.frontPageSK, 
			frontPageRO: this.item.frontPageRO, 
			frontPageHU: this.item.frontPageHU, 
			frontPageEN: this.item.frontPageEN, 
			activeCP: this.item.activeCP, 
			activeSR: this.item.activeCP, 
			activeSK: this.item.activeSK, 
			activeRO: this.item.activeRO, 
			activeHU: this.item.activeHU, 
			activeEN: this.item.activeEN 
			};
			console.log(payload);
      this.apiResponse = await api.updatePage(payload)
		console.log(this.apiResponse);
		this.saved=true;
		setTimeout(()=>{this.saved=false;},1000);
		//FILE UPLOAD

		if (this.selectedFiles.length > 0) {
		console.log("filePayload"); console.log(this.filePayload); console.log("filePayload");
      //this.filePayload.append('pageId', this.item.id);
      //this.filePayload.append('pageId', 320345);

		//console.log("filePayload"); console.log(filePayload); console.log("filePayload");

			this.isUploading=true
       this.apiResponseFile = await api.uploadFiles(this.filePayload, this.item.id)
				this.isUploading=false
      //api.otto();
		//FILE UPLOAD
  	  	this.goToShowPage(); 
		}
		//Upload Files

      //this.pageCategoryPath = await api.getPageCategoryPath(pageCategoryIds[0])
	 },
    goToShowPage() {
      this.$router.replace(`/showpage/${this.item.id}`);
    },
	 async deleteFiles(){ 
		 console.log("MMM");
		 //  await this.$store.dispatch("page/deleteFiles", this.checkedFiles);
		 //console.log(typeof this.checkedFiles);
		 console.log("xxx"+this.checkedFiles);

		 //for (let i = 0; i<this.checkedFiles.length ; i++) {
		let idDelete=null;
		for (const key in this.checkedFiles){
			//console.log(`${key} : ${this.checkedFiles[key]}`)
			idDelete=this.checkedFiles[key];
			 this.apiResponse = await api.deleteFiles(idDelete);
			 //console.log(this.apiResponse);
		 }
  	  	this.goToShowPage(); 
	 },
    goToDashboard() {
      this.$router.replace("/dashboard");
    },
    copyToClipboard(p) {
			let href=`${this.apiUrl}/public/pagefiles/${p}`
			navigator.clipboard.writeText(href);
			console.log("path: "+href)
    },

    async onFileSelected(event) {
      this.storeFiles(event)
      this.showSuccess = false;
      this.selectedFiles = event.target.files;
      this.selectedFile = event.target.files[0];
      var selectedFilesDesc = [];
      this.selectedFiles.forEach((item) => {
        selectedFilesDesc.push({ name: item.name, size: item.size });
      });
      this.selectedFilesDescriptions = selectedFilesDesc;

      var formData = new FormData();
      //formData.append('pageId', this.idCreated);
      //formData.append('pageId', 320374);
		console.log("this.selectedFiles");
		console.log(this.selectedFiles);
		console.log("this.selectedFiles");

      this.selectedFiles.forEach((item) => {
        formData.append("pageFile", item, item.name);
		  console.log("x");
      });

      //formData.append('pageId', '320345');
		//console.log("formData");console.log(formData);console.log("formData"); 
		this.filePayload=formData;
		/*

		console.log("ddd");console.log(this.filePayload);console.log("ddd"); 
		for(var pair of formData.entries()) {
		   console.log(pair[0]+ ', '+ pair[1]);
		   console.log(pair[1]);
		}
		*/

		//console.log("filePayload"); console.log(this.filePayload); console.log("filePayload");

      //this.apiResponseFile = await api.uploadFiles(formData);
    },
    goToPage(router, id) {
      if (id > 0) {
        this.$router.push({ path: `/${router}/${id}` });
      } else {
        this.$router.push({ path: `/${router}` });
      }
    },
    showPagesInCategory(id) {
        this.$router.push({ path: `/adminlistpageincategory/${id}` });
    },
		activateLg(lg) {
			 console.log(lg);
			 this.flagAdmin[lg]=true
				for(var key in this.flagAdmin) {
				 if (lg==key) { this.flagAdmin[key]=true;}
				else { this.flagAdmin[key]=false;}
				}
				this.isOoo=false;

		}
	} // end of methos
}
</script>

<style scoped>
.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border: 1px solid #ccc;
}
.flex-container {
	display:flex;
	justify-content: center;
}
.flex-item {
	flex:1;
}
form {
margin: 1rem;
padding: 1rem;
}

.upload-files {
  background-color: #25809c;
}

.form-control {
margin: 0.5rem 0;
border: none;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

.error {
color:red;
}
.success {
	color:green;
	font-weight:bold;
}

.delete-files {
	color:white;
	background-color:red;

}
.delete-files-check {
margin: 1rem;
padding: 1rem;
display: block;
}
.deleteFile{
}

label {
  margin-top:0.5rem;
  margin-right:0.5rem;
}
input {
	margin: 0.1rem;
}

input[type="checkbox"] + label {
  font-weight: normal;
  display: inline;
}
input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: #f0e6fd;
  outline: none;
  border-color: #3d008d;
}
input[type="checkbox"] {
  display: inline;
  width: auto;
  border: none;
}
input[type="checkbox"]:focus {
  outline: #3d008d solid 1px;
}

.page-category-tree {
    background-color: #d8f0f2;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.current-page-category {
  background-color: #25809c;
  color:white;
  font-size:0.9em;
    padding: 5px;
    border: 1px solid #0000cc;;
    border-radius: 4px;
}
.file-name {
	font-size: 0.7rem;
	text-align:left;
	 cursor:pointer;
	 margin-top:5px;
	 padding:3px;
    border: 1px solid #000000;;
}
.page-category-list {
	cursor:pointer;
	font-size:1.2em
}
.flex-lg-container {
	 background-color:#ccc;
	 display:flex;
	 margin-top:10px; 
}
.flex-lg-item {
	 cursor:pointer;
	 padding-right:10px; 
	 opacity: 0.25;
}
.lg-box {

    border: 2px solid #ccc;
}
.ooo{
	 opacity: 1.0;
}
.file-upload {
	 background-color:#f7f7f7;
   border: 1px solid #aaa;;
   border-radius: 4px;
	 padding:1rem;
}
.back-go-dasboard {
	 background-color:#880000;
}
input[type='number']{
    width: 80px;
} 
.page-title {
	 font-size:1rem;
 }

.flex-items-attachments {
	text-align:center;
	margin:5px;
	font-size:0.8rem;
}
input[type="checkbox"] {
    cursor: pointer;
}
</style>


