<template>
  <div>
    <base-dialog :show="isLoading" title="Saving..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <base-card>
      <h2>Update User</h2>
<!--PAGE DASHBOARD -->
 <div class="dashboard-container">
 <base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lg] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->
      <form @submit.prevent="submitForm" v-show="!apiSuccess">
        <div class="form-control">
          <label for="email" class="user-label">E-Mail: <span class="no-change">{{email}}</span></label>
		 
        </div>
        <div class="form-control">
          <label for="email" class="user-label">Username: <span class="no-change">{{username}}</span></label>
        </div>

			<div v-if="setNewPassword" class="change-password">
        <div>
          <label for="password" class="user-label">Password: </label>
          <input type="password" id="password" v-model.trim="password" />
        </div>
        <div>
          <label  class="user-label" for="confirm-password">Confirm Password: </label>
          <input type="password" id="passwordConfirm" v-model.trim="passwordConfirm" />
        </div>
        </div>

          <base-button type="button" @click="showPasswordInputt" class="set-new-password">{{ buttonCaption }}</base-button>




        <!-- Page Privilege -->
  			  <ul id="page-category-tree" class="page-category-tree">
		      <tree-item :item=treeDataDB></tree-item>
			</ul>

        <!-- /Page Privilege -->

        <!-- Module Privilege -->
        <div class="form-control">
          <label for="email" class="user-label">Module Prilivlage</label>
			<div v-for="item in moduleArrayObject" :key="item.id">
		    <label  class="checkbox-inline">{{item.name}}</label>
		    <input type="checkbox" v-model="item.selected" />
			</div>
        </div>
        <!-- Module Privilege -->

        <!-- Role Privilege -->
        <div class="form-control">
          <label for="email" class="user-label">User Type</label>

<div  v-for="item in roleArrayObject">
	<input 
	name="myfield" 
	type="radio"
	v-model="roleNew"
	v-bind:checked="item.value==role"
	v-bind:value="item.value"
	>
    <label>{{ item.name }}</label>
</div>
</div>
        <!-- Role Privilege -->

		<input type="hidden" name="setNewPassword" class="form-control" :value="setNewPassword">
        <!-- Prevalidation Error -->
        <div class="d-flex" v-if="!formIsValid">
          <img
            class="mr-2"
            alt=""
            src="../../assets/error.svg"
            width="35"
            style="vertical-align: middle"
          />
          <p class="error mt-0 d-flex align-items-center">
            {{ errorMessage }}
          </p>
        </div>
        <!--/ Prevalidation Error -->

        <!-- Successfully saved -->
			<span v-if="saved" class="success">
          <img alt="Saved successfully" src="../../assets/success.svg" width="35" hspace="10" vspace="10" style="vertical-align: middle" />
			Saved successfully
			</span>
        <!-- / Successfully saved -->
        <!-- API Error -->
        <div class="d-flex mt-2" v-if="apiError">
          <img
            class="mr-2"
            alt=""
            src="../../assets/error.svg"
            width="35"
            style="vertical-align: middle"
          />
          <p class="error mt-0 d-flex align-items-center">
            {{ apiEerrorMessage }}
          </p>
        </div>
        <!-- /API Error -->

        <div class="d-flex justify-content-center">
          <base-button type="button" @click="submitForm">Save</base-button>
        </div>
      </form>

    </base-card>
  </div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import TreeItem from './TreeItem.vue';
export default {
  components: {
    TreeItem
  },
  data() {
    return {
		appMessages:appMessages,
		lg:"sr",
      email: "",
      username: "",
      password: "",
      passwordConfirm: "",
      pagePrivilege: "",
      modulePrivilege: "",
      role: "",
      roleNew: "",
		saved:false,
		setNewPassword:false,
		buttonCaption: "Set New Password",
      showSuccess: true,
      formIsValid: true,
      isLoading: false,
      errorX: this.$store.getters.apiError,
      errorMessage: "",
		apiResponse:"",
		treeDataDB: { title: "Please Select Category ", id:0, children:[] },
		moduleArray: [],
		moduleArrayObject: [
		{"id": 1, "name": "Users","selected": false },
		{"id": 2, "name": "Page Categories", "selected": false  },
		{"id": 3, "name": "Pages","selected": false }
		],
		roleArrayObject: [
		{"id": 1, "name": "User","value": "user","selected": false },
		{"id": 2, "name": "Admin","value": "admin","selected": false },
		{"id": 3, "name": "Super Admin","value": "superadmin","selected": false }
		]

  }
  },
  computed: {
    apiSuccess() {
      return this.$store.getters.apiSuccess;
    },
    apiError() {
      return this.$store.getters.apiError;
    },
    apiEerrorMessage() {
      return this.$store.getters.apiErrorMessage;
    },
  },
  mounted() {
    this.$store.commit("setApiError", false);
    this.$store.commit("setApiErrorMessage", "");
   this.$store.commit("page/setShowCarusel",false);
  },

  created() {
    this.loadItem(this.$route.params.id)
    this.apiUrl = process.env.VUE_APP_API_URL;
    this.loadPageCategories()

  },
  methods: {

	  async loadPageCategories () {

		  this.loading = true
		  this.items = await api.getPageCategories()
		  this.loading = false

			this.tree=this.items;
			this.convertToJSONTree(this.items)
			this.treeDataDB.children=this.tree;
			this.$store.commit("page/deletePageCategoryIds")

			let userPagePrivilege=this.pagePrivilege.split(",").map((item) => { 
       			return parseInt(item, 10);
			});
			userPagePrivilege.forEach((element, index)=>{ 
				this.$store.commit("page/setPageCategoryIds",element); 
			});

	  },
	  convertToJSONTree  (data)  {
		  const tree = data.map(e => ({...e}))
			  .sort((a, b) => a.id - b.id)
			  .reduce((a, e) => {
					  a[e.id] = a[e.id] || e;
					  a[e.parentId] = a[e.parentId] || {};
					  const parent = a[e.parentId];
					  parent.children = parent.children || [];
					  parent.children.push(e);
					  return a;
					  }, {})
		  ;
		  this.tree= Object.values(tree) .find(e => e.id === undefined).children;
	  },

	 //Page Category Tree
    async submitForm() {
      this.formIsValid = true;
      //if ( this.email === '' || !this.email.includes('@')) {
      if (!this.validateEmail(this.email)) {
        this.formIsValid = false;
        this.errorMessage = "Please enter a valid e-mail address!";
        return;
      }
      if (!this.validateUsername(this.username)) {
        this.formIsValid = false;
        this.errorMessage = "Please enter a valid username!";
        return;
      }
      //if (this.password.length < 8) {
      if (!this.validatePassword(this.password)) {
        this.formIsValid = false;
        this.errorMessage =
          "Password must be at least 7 characters long, contain at least one upper case and two digits!";
        return;
      }

      if (this.password != this.passwordConfirm) {
        this.formIsValid = false;
        this.errorMessage = "Password and Confirm Password do not match!";
        return;
      }

      //this.isLoading = true;
		let mP=""
		this.moduleArrayObject.forEach((element,index)=>{
			if (element.selected) { mP=mP+element.id+"," }
		})
		mP=mP.slice(0, -1)

		let pP=""
		this.$store.getters['page/pageCategoryIds'].map(String).forEach((element)=>{ 
			pP=pP+element+","
		})
		pP=pP.slice(0, -1)

      const actionPayload = {
        id: this.$route.params.id,
        password: this.password,
        pagePrivilege: pP,
        modulePrivilege: mP,
        role: this.roleNew,
		  setNewPassword:this.setNewPassword
      };

      try {
      	this.apiResponse = await api.updateUser(actionPayload)
			this.saved=true;
			setTimeout(()=>{
				this.saved=false;
     			this.$router.replace("/adminuserdashboard");
				},1000);
      } catch (err) {
        this.error = err.message || "Failed to authenticate, try later.";
      }
      this.error = this.$store.getters.apiError;

      this.isLoading = false;
    },

    validateEmail(emailToTest) {
      const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return expression.test(emailToTest.toLowerCase());
    },

    validateUsername(usernameToTest) {
      if (usernameToTest == "") {
        return false;
      } else {
        return true;
      }
    },
    validatePassword(passwordToTest) {
      const expression = /^(?=.*[A-Z])(?=.*[0-9].*[0-9])(?=.*[a-z])/;
      var valid = expression.test(passwordToTest);
      return valid;
    },

    handleError() {
      this.error = null;
    },

    async loadItem (id) {
      this.loading = true
      this.item = await api.getUserAdmin(id)
		//console.log(this.item)
		this.email=this.item.email
		this.username=this.item.username
		this.password=this.item.password
		this.passwordConfirm=this.item.password
		this.pagePrivilege=this.item.pagePrivilege
		this.modulePrivilege=this.item.modulePrivilege
		this.role=this.item.role
		this.roleNew=this.item.role
		this.moduleArray=this.modulePrivilege.split(",").map(Number);

		//for (const element of this.moduleArrayObject) {

		this.moduleArrayObject.forEach((element,index)=>{
			if (this.moduleArray.includes(element.id)) {
				this.moduleArrayObject[index].selected=true
			}
		})
	 },
		showPasswordInputt () {
			this.setNewPassword=!this.setNewPassword
			if (this.setNewPassword) {
				this.buttonCaption="Cancel"
			} else {
				this.buttonCaption="Set New Password"
			}
		},
  goToDashboard() {
     this.$router.replace("/dashboard");
  },
  }
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
  border: none;
}

.error {
  color: red;
  font-size: 16px;
}

.set-new-password {
background-color: #25809c;
font-size: 0.9rem;
}
.change-password {
    background-color: #d8f0f2;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid;
    border-radius: 4px;
}
.success {
	color:green;
	font-weight:bold;
}

.no-change {
color: #cc0000;
}
label {
	margin: 5px;
}
.user-label {
	font-weight:bold;
}

.page-category-tree {
    background-color: #d8f0f2;
    border-color: #ebccd1;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.dashboard-container {
	display:flex;
	justify-content: center;
	margin-bottom: 0.4rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.back-go-dasboard {
	 background-color:#880000;
}

</style>
