<template>
		<ul id="MobileNav" class="mobile-nav">

			<li><router-link to="/home">{{ appMessages.menuHome.[lgs] }}</router-link></li>

			<li class="lvl1 parent megamenu"><a href="#">{{ appMessages.menuFirst.[lg] }}<i class="an an-plus" @click="togleMobileSubmenu(0)"></i></a>
				<div v-if="showMobileSubmenu[0]" class="mobile-submenu">
					<li  v-for="item in menu[0][lgs]">
					<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</div>
			</li>

			<li class="lvl1 parent megamenu">
				<a href="#">{{ appMessages.menuSecond.[lgs] }}<i class="an an-plus" @click="togleMobileSubmenu(1)"></i></a>
				<div v-if="showMobileSubmenu[1]" class="mobile-submenu">
					<li  v-for="item in menu[1][lgs]">
					<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</div>
			</li>

			<li class="lvl1 parent megamenu"><a href="#">{{ appMessages.menuThird.[lgs] }}<i class="an an-plus" @click="togleMobileSubmenu(2)"></i></a>
				<div v-if="showMobileSubmenu[2]" class="mobile-submenu">
					<li  v-for="item in menu[2][lgs]">
					<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</div>
			</li>

			<li class="lvl1 parent megamenu"><a href="#">{{ appMessages.menuFourth.[lgs] }}<i class="an an-plus" @click="togleMobileSubmenu(3)"></i></a>
				<div v-if="showMobileSubmenu[3]" class="mobile-submenu">
					<li  v-for="item in menu[3][lgs]">
					<router-link :to=item.root>{{item.title}}</router-link>
					</li>
				</div>
			</li>

		</ul>
</template>

<script>

import { appMessages } from "../../translations/messages";
import { menuGlobal } from "../../translations/menu";

export default {
data() {
	return {
			appMessages: appMessages,
			menu: menuGlobal.menu,
		 	lg:"",
			showMobileSubmenu: [false, false, false, false],
	}
},
computed: {
 lgs () {
	this.lg=this.$store.getters["lg"]
 	return this.$store.getters["lg"]
 }
},
mounted() {
		this.lg = this.$store.getters["lg"];
		console.log(this.menuGlobal)
},
methods: {
		togleMobileSubmenu(i) {
			this.showMobileSubmenu[i] = !this.showMobileSubmenu[i];
		},
},
}
</script>
<style>
.router-link-active {
	color: #0000ff;
	border: 1px solid red;
}
.menu-bar {
	background-color: #eeeeee;
}

.search-container {
	display: flex;
	cursor: pointer;
}
.search-input {
	flex: 14;
}
.search-close-container {
	flex: 1;
	color: #888888;
}
.search-submit-container {
	flex: 1;
	color: #888888;
}
.mobile-submenu {
	display: block;
}
</style>
