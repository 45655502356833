<template>
  <div>
    <section class="section section-sm">
      <div class="container">
        <h1>{{ appMessages.listPagesByCaotegory.[lgs] }}</h1>

<!--PAGE DASHBOARD -->
 <div class="dashboard-flex-container">
 	<base-button type="button" @click="goToPage('admincreatepage')">{{ appMessages.createPage.[lgs] }}</base-button>
 	<base-button class="back-go-dasboard" type="button" @click="goToDashboard">{{ appMessages.dashboard.[lgs] }}</base-button>
 </div>
<!--END PAGE DASHBOARD -->

    <ul id="page-category-tree">
      <tree-item-simple :item=treeDataDB ></tree-item-simple>
	</ul>
  </div>
    </section>
  </div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import TreeItemSimple from './TreeItemSimple.vue';

export default {

  components: {
    TreeItemSimple
  },
  data() {
    return {
		appMessages:appMessages,
			lg:"sr",
      items: [],
		loading: false, 
      tree: "",
		treeDataDB: { title: "Click on the category name to list pages ", id:0, children:[] },
    };
  },
  mounted() {
  },

  created() {
    this.loadPageCategories()
    this.apiUrl = process.env.VUE_APP_API_URL;

  },
  computed: {
		 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
		 }
  },

  methods: {

	  async loadPageCategories (id) {
		  this.loading = true
		  this.items = await api.getPageCategories()
		  this.loading = false

			this.tree=this.items;
			this.convertToJSONTree(this.items)
			this.treeDataDB.children=this.tree;
	  },
	  convertToJSONTree  (data)  {
		  const tree = data.map(e => ({...e}))
			  .sort((a, b) => a.id - b.id)
			  .reduce((a, e) => {
					  a[e.id] = a[e.id] || e;
					  a[e.parentId] = a[e.parentId] || {};
					  const parent = a[e.parentId];
					  parent.children = parent.children || [];
					  parent.children.push(e);
					  return a;
					  }, {})
		  ;
		  this.tree= Object.values(tree) .find(e => e.id === undefined).children;
	  },
    goToMyPages() {
      this.$router.replace("adminlistmypage");
    },
    goToPage(router) {
      this.$router.push({ path: `/${router}` });
      //this.$router.push({ path: `/${router}/${id}` });
    },
    goToDashboard() {
      this.$router.replace("/dashboard");
    },
  },
};
</script>

<style scoped>
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}

.delete {
  background-color: #bb0000;
}
.delete:hover {
  background-color: #ff0000;
}
.delete_title {
  text-align: center;
}
.id {
  color: gray;
}
.centered {
  text-align: center;
  display: block;
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.passivePageCategory {
  font-weight: normal;
  color: #000000;
}
.dashboard-container {
	justify-content: center;
	margin-bottom: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dashboard-flex-container {
	display:flex;
	justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.dashboard-title {
	 font-size:1.1rem;
}
.back-go-dasboard {
	 background-color:#880000;
}
</style>
