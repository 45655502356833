export const appMessages = {
	appTitle: {
		cp: "Општина Ковачица",
		sr: "Opština Kovačica",
		sk: "Obec Kovačica",	
		ro: "Municipiul Kovačica",	
		hu: "Antalfalva Község",	
		en: "Municipality of Kovačica"	
	},
	// menu
	menuHome: {
		cp: "Насловна",
		sr: "Naslovna",
		sk: "Domov",
		ro: "Acasă",	
		hu: "Címoldal",	
		en: "Home"	
	},
	language: {
		cp: "Српски",
		sr: "Srpski",
		sk: "Slovenský",
		ro: "Română",	
		hu: "Magyar",	
		en: "English"	
	},
	signIn: {
		cp: "Пријава",
		sr: "Prijava",
		sk: "Prihlásiť sa",
		ro: "Conectare",	
		hu: "Bejelentkezés",	
		en: "Sign In"	
	},
	signUp: {
		cp: "Регистрација",
		sr: "Registracija",
		sk: "Prihlásiť Se",
		ro: "Inscrie-te",	
		hu: "Regisztráció",	
		en: "Sign Up"	
	},
	search: {
		cp: "Претрага",
		sr: "Pretraga",
		sk: "Vyhľadávanie",
		ro: "Căutare",	
		hu: "Keresés",	
		en: "Search"	
	},
	searchForm: {
		cp: "Задајте услов претраге ...",
		sr: "Zadajte uslov pretrage ...",
		sk: "Zadajte podmienku vyhľadávania...",
		ro: "Specificați condiția de căutare...",	
		hu: "Adja meg a keresés feltételét...",	
		en: "Enter search condition ..."	
	},
	searchCount: {
		cp: "Број погодака",
		sr: "Broj pogodaka",
		sk: "Počet zásahov",
		ro: "Numărul de accesări",	
		hu: "Találatok száma",	
		en: "Number of hits"	
	},
	searchInput: {
		cp: "Услов претраге",
		sr: "Uslov pretrage",
		sk: "Podmienku vyhľadávania",
		ro: "Condiția de căutare",	
		hu: "Keresés feltétele",	
		en: "Search condition"	
	},
	password: {
		cp: "Лозинка",
		sr: "Lozinka",
		sk: "Heslo",
		ro: "Parola",	
		hu: "Jelszó",	
		en: "Pasrword"	
	},
	confirmPassword: {
		cp: "Поновљена лозинка",
		sr: "Ponovljena lozinka",
		sk: "Potvrďte heslo",
		ro: "Confirmă parola",	
		hu: "Jelszó megerősítése",	
		en: "Confirm Password"	
	},
	forgotPassword: {
		cp: "Заборавили сте лозинку?",
		sr: "Zaboravili ste lozinku?",
		sk: "Zabudol si heslo?",
		ro: "Ați uitat parola?",	
		hu: "Elfelejtette jelszavát?",	
		en: "Forgot Password?"	
	},
	underConstruction: {
		cp: "Ова страница је у фази израде",
		sr: "Ova stranica je u fazi izrade",
		sk: "Táto stránka je vo výstavbe",
		ro: "Această pagină este în construcție",	
		hu: "Az oldal fejlesztés allatt áll",	
		en: "This page is under construction"	
	},
	underDevelopment: {
		cp: "Презентација је у фази развоја",
		sr: "Prezentacija je u fazi razvoja",
		sk: "Prezentácia je vo vývoji",
		ro: "Prezentarea este în curs de dezvoltare",	
		hu: "Az oldal további fejlesztés allatt áll",	
		en: "The presentation is under development"	
	},
	copyright: {
		cp: "Сва права су задржана",
		sr: "Sva prava su zadržana",
		sk: "Všetky práva vyhradené",
		ro: "Toate drepturile rezervate",	
		hu: "Minden jog fenntartva",	
		en: "All rights reserved"	
	},
	contact: {
		cp: "Контакт",
		sr: "Kontakt",
		sk: "Kontakt",
		ro: "Contact",	
		hu: "Elérhtőség",	
		en: "Contact"	
	},
	street: {
		cp: "Маршала Тита",
		sr: "Maršala Tita",
		sk: "Maršala Tita",
		ro: "Maršala Tita",	
		hu: "Titó Marsal",	
		en: "Maršala Tita"	
	},
	phone: {
		cp: "Телефон",
		sr: "Telefon",
		sk: "Telefon",
		ro: "Telefon",	
		hu: "Telefon",	
		en: "Phone"	
	},
	documents: {
		cp: "Документи",
		sr: "Dokumenti",
		sk: "Dokumenty",
		ro: "Documente",	
		hu: "Dokumentumok",	
		en: "Documents"	
	},
	popularPages: {
		cp: "Популарне странице",
		sr: "Popularne stranice",
		sk: "Obľúbené stránky",
		ro: "Pagini populare",	
		hu: "Népszerű oldalak",	
		en: "Documents"	
	},
	footer1: {
		cp: "Вести",
		sr: "Vesti",
		sk: "Novinka",
		ro: "Știri",	
		hu: "Hírek",	
		en: "News"	
	},
	footer2: {
		cp: "О нама",
		sr: "O nama",
		sk: "O nás",
		ro: "Despre noi",	
		hu: "Rólunk",	
		en: "About Us"	
	},
	footer3: {
		cp: "Службени лист",
		sr: "Službeni list",
		sk: "Úradný vestník",
		ro: "Jurnalul Oficial",	
		hu: "Hivatalos lap",	
		en: "Official Journal"	
	},
	footer4: {
		cp: "Обавештења",
		sr: "Obaveštenja",
		sk: "Upozornenia",
		ro: "Notificări",	
		hu: "Értesítések",	
		en: "Notifications"	
	},
	footer5: {
		cp: "Конкурси",
		sr: "Konkursi",
		sk: "Súťaže",
		ro: "Concursuri",	
		hu: "Pályázatok",	
		en: "Applications"	
	},
	socialNetworks: {
		cp: "Друштвене мреже",
		sr: "Društvene mreže",
		sk: "Sociálne siete",
		ro: "Retele sociale",	
		hu: "Közösségi oldalak",	
		en: "Social networks"	
	},
	sisterCity: {
		cp: "Партнерске Општине",
		sr: "Partnerske Opštine",
		sk: "Sesterské mestá",
		ro: "Orașe surori",	
		hu: "Testvérvárosok",	
		en: "Sister Cities"	
	},
	allwaysWithYou: {
		cp: "Увек на услузи грађанима",
		sr: "Uvek na usluzi građanima",
		sk: "Vždy v službách občanov",
		ro: "Întotdeauna în slujba cetățenilor",	
		hu: "Mindig a polgárok szolgálatában",	
		en: "Always at the service of citizens"	
	},
	comeBackLater: {
		cp: "Молим Вас, вратите се касније!",
		sr: "Molim Vas, vratite se kasnije!",
		sk: "Prosím vráť sa neskôr!",
		ro: "Te rog intoarce-te mai tarziu!",	
		hu: "Kérjük látogasson el később!",	
		en: "Please come back later!"	
	},
	call: {
		cp: "Позовите број",
		sr: "Pozovite broj",
		sk: "Zavolajte nám",
		ro: "Sună-ne",	
		hu: "Hívjon minket",	
		en: "Call Us"	
	},
	italy: {
		cp: "Италија",
		sr: "Italija",
		sk: "Taliansko",
		ro: "Italia",	
		hu: "Olaszország",	
		en: "Italy"	
	},
	hungary: {
		cp: "Мађарска",
		sr: "Mađarska",
		sk: "Maďarsko",
		ro: "Ungaria",	
		hu: "Magyarország",	
		en: "Hungary"	
	},
	serbia: {
		cp: "Србија",
		sr: "Srbija",
		sk: "Srbsko",
		ro: "Serbia",	
		hu: "Szerbia",	
		en: "Serbia"	
	},
	slovakia: {
		cp: "Словачка",
		sr: "Slovačka",
		sk: "Slovensko",
		ro: "Slovacia",	
		hu: "Szlovákia",	
		en: "Slovakia"	
	},
	bulgaria: {
		cp: "Бугарска",
		sr: "Bugarska",
		sk: "Bulharsko",
		ro: "Bulgaria",	
		hu: "Bulgária",	
		en: "Bulgaria"	
	},
	romania: {
		cp: "Румунија",
		sr: "Rumunija",
		sk: "Rumunsko",
		ro: "România",	
		hu: "Románia",	
		en: "Romania"	
	},
	banskaBistrica: {
		cp: "Банска Бистрица",
		sr: "Banska Bistrica",
		sk: "Banská Bystrica",
		ro: "Banská Bystrica",	
		hu: "Besztercebánya",	
		en: "Banska Bystrica"	
	},
	rekas: {
		cp: "Рекаш",
		sr: "Rekaš",
		sk: "Rekaš",
		ro: "Recaș",	
		hu: "Temecpékás",	
		en: "Rekasch"	
	},
	sumskiSvetDjordje: {
		cp: "Шумски Свети Ђорђе",
		sr: "Šumski Sveti Đorđe",
		sk: "Šumski Sveti Đorđe",
		ro: "Sfântu Gheorghe",	
		hu: "Sepsiszentgyörgy",	
		en: "Sfântu Gheorghe"	
	},
	availableLg: {
		cp: "Доступни језици",
		sr: "Dostupni jezici",
		sk: "Dostupné jazyky",
		ro: "Limbi disponibile",	
		hu: "Elérhető nyelvek",	
		en: "Available Languages"	
	},
	currentLg: {
		cp: "Изабрани језик",
		sr: "Izabrani jezik",
		sk: "Aktuálny jazyk",
		ro: "Limba curentă",	
		hu: "Jelenlegi nyelv",	
		en: "Current Language"	
	},

	menuFirst: {
		cp: "О Ковачици",
		sr: "O Kovačici",
		sk: "O Kovačici",
		ro: "Despre Covăcița",	
		hu: "Antalfalváról",	
		en: "About Kavačica"	
	},
	menuSecond: {
		cp: "Актуелности",
		sr: "Aktuelnosti",
		sk: "Aktuality",
		ro: "Actualități",	
		hu: "Aktualitás",	
		en: "Actualities"	
	},
	menuThird: {
		cp: "Документи",
		sr: "Dokumenti",
		sk: "Dokumenty",
		ro: "Documente",	
		hu: "Dokumentumok",	
		en: "Documents"	
	},
	menuFourth: {
		cp: "Привреда",
		sr: "Privreda",
		sk: "Priemysel",
		ro: "Industrie",	
		hu: "Ipar",	
		en: "Industry"	
	},

// end menu
	save: {
		cp: "Сними",	
		sr: "Snimi",	
		sk: "Uložiť",	
		ro: "Salva",	
		hu: "Ment",	
		en: "Save"	
	},
	delete: {
		cp: "Бриши",	
		sr: "Briši",	
		sk: "Odstrániť",	
		ro: "Șterge",	
		hu: "Törlés",	
		en: "Delete"	
	},
	pages: {
		cp: "Странице",	
		sr: "Stranice",	
		sk: "Stránky",	
		ro: "Pagini",	
		hu: "Oldalak",	
		en: "Pages"	
	},

	listPagesByCaotegory: {
		cp: "Странице по категоријама",	
		sr: "Stranice po kategorijama",	
		sk: "Stránky podľa kategórie",	
		ro: "Pagini pe categorii",	
		hu: "Oldalak kategória szerint",	
		en: "Pages by category"	
	},
	updateFrontpage: {
		cp: "Ажурирање насловне стране",	
		sr: "Ažuriranje naslovne strane",	
		sk: "Zmeniť titulnú stranu",	
		ro: "Schimbați pagina de titlu",	
		hu: "Címoldal módosítása",	
		en: "Update Frontpage"	
	},
	logedInUser: {
		cp: "Пријављени корисник",	
		sr: "Prijavljeni korisnik",	
		sk: "Prihlásený užívateľ",	
		ro: "Utilizator autentificat",	
		hu: "Bejelentkezett falhasználó",	
		en: "Logged in User"	
	},
	saveSuccess: {
		cp: "Успешно снимање",	
		sr: "Uspešno snimanje",	
		sk: "Úspešne uložené",	
		ro: "Salvat cu succes",	
		hu: "Sikeres mentés",	
		en: "Saved Succesrfuly"	
	},
	createPage: {
		cp: "Креирање нове странице",	
		sr: "Kreiranje nove stranice",	
		sk: "Vytvoriť novú stránku",	
		ro: "Creați o pagină nouă",	
		hu: "Új oldal létrehozása",	
		en: "Create New Page"	
	},
	selectFilesToUpload: {
		cp: "Изаберите фајл(ове) за копирање",	
		sr: "Izaberite fajl(ove) za kopiranje",	
		sk: "Vyberte súbory, ktoré chcete nahrať",	
		ro: "Selectați fișierele de încărcat",	
		hu: "Válassza kia a file(oka)t feltöltésre",	
		en: "Select files(s) to upload"	
	},
	deleteSelectedFiles: {
		cp: "Избриши изабране фајлове",	
		sr: "Izbriši izabrane fajlove",	
		sk: "Odstrániť vybraté súbory",	
		ro: "Ștergeți fișierele selectate",	
		hu: "Kijelölt file(ok) törlése",	
		en: "Delete selected files(s)"	
	},
	noPictures: {
		cp: "Нема слика на овој страници",	
		sr: "Nema slika na ovoj stranici",	
		sk: "Žiadne obrázky súvisiace s touto stránkou",	
		ro: "Nu există imagini legate de această pagină",	
		hu: "Az oldalhoz nem tartozik fénykép",	
		en: "No images related to this page"	
	},
	noDocuments: {
		cp: "Нема докумената на овој страници",	
		sr: "Nema dokumenata na ovoj stranici",	
		sk: "Žiadne dokumenty súvisiace s touto stránkou",	
		ro: "Nu există documente legate de această pagină",	
		hu: "Az oldalhoz nem tartozik dokumentum",	
		en: "No documents related to this page"	
	},
	dashboard: {
		cp: "Контролна табла",	
		sr: "Kontrolna tabla",	
		sk: "Dashboard",	
		ro: "Bord",	
		hu: "Irányítópult",	
		en: "Dashboard",	
	},
	showPage: {
		cp: "Покажи страницу",	
		sr: "Pokaži stranicu",	
		sk: "Zobraziť stránku",	
		ro: "Afișează pagina",	
		hu: "Oldal megtekintése",	
		en: "Show Page"	
	},
	documentsRelatedToThisPage: {
		cp: "Документи на овој страници",	
		sr: "Dokumenti na ovoj stranici",	
		sk: "Az oldalhoz tartozó dokumentumok",	
		ro: "Az oldalhoz tartozó dokumentumok",	
		hu: "Az oldalhoz tartozó dokumentumok",	
		en: "Documents related to this page"	
	},
	imagesRelatedToThisPage: {
		cp: "Слике на овој страници",	
		sr: "Slike na ovoj stranici",	
		sk: "Az oldalhoz tartozó képek",	
		ro: "Az oldalhoz tartozó képek",	
		hu: "Az oldalhoz tartozó képek",	
		en: "Images related to this page"	
	},
	succesfulySaves: {
		cp: "Успешно снимање",	
		sr: "Uspešno snimanje",	
		sk: "Úspešne uložené",	
		ro: "Salvat cu succes",	
		hu: "Sikeres mentés",	
		en: "Saved Successfuly"	
	},
	category: {
		cp: "Категорија",
		sr: "Kategorija",
		sk: "Kategória",	
		ro: "Categorie",	
		hu: "Kategória",	
		en: "Category"	
	},
	logout: {
		cp: "Одјава",
		sr: "Odjava",
		sk: "Odhlásiť sa",	
		ro: "Deconectare",	
		hu: "Kijelentkezés",	
		en: "Logout"	
	},
	myProfile: {
		cp: "Мој профил",
		sr: "Moj profil",
		sk: "Môj profil",	
		ro: "Profilul meu",	
		hu: "Saját profilom",	
		en: "My Profile"	
	},
	users: {
		cp: "Корисници",
		sr: "Korisnici",
		sk: "Používatelia",	
		ro: "Utilizatori",	
		hu: "Felhasználók",	
		en: "Users"	
	},
	newUsers: {
		cp: "Нови корисник",
		sr: "Novi korisnik",
		sk: "Nový užívateľ",	
		ro: "Utilizator nou",	
		hu: "Új felhasználó",	
		en: "New User"	
	},
	categories: {
		cp: "Категорије",
		sr: "Kategorije",
		sk: "Categories",	
		ro: "Categories",	
		hu: "Kategóriák",
		en: "Categories"	
	},
	caruselSubTitle: {
		cp: "Званична презентација општине Ковачица",
		sr: "Zvanična prezentacija opštine Kovačica",
		sk: "Oficiálna prezentácia obec Kovačica",	
		ro: "Prezentarea oficială a municipiului Kovačica",	
		hu: "Antalfalva Község hivatalos honlapja",	
		en: "Official presentation of Municipality of Kovačica"	
	},
	latest: {
		cp: "Најновије",
		sr: "Najnovije",
		sk: "Najnovšie",	
		ro: "Cele mai recente",	
		hu: "Legfrissebb",	
		en: "Latest",	
	},

	pageListInCategory: {
		cp: "Листа страница у категорији",
		sr: "Lista stranica u kategoriji",
		sk: "Stránky v kategórii",	
		ro: "Pagini din categorie",	
		hu: "A kategória oldalai",	
		en: "Pages in category"	
	},
	pageUpdate: {
		cp: "Измена странице",
		sr: "Izmena stranice",
		sk: "Aktualizácia stránky",	
		ro: "Actualizare pagină",	
		hu: "Oldal frissítése",	
		en: "Page Update"	
	},
	pageDate: {
		cp: "Датум",
		sr: "Datum",	
		sk: "Dátum",	
		ro: "Data",	
		hu: "Dátum",	
		en: "Date"	
	},
	pageTitle: {
		cp: "Наслов",
		sk: "Názov",	
		ro: "Titlu",	
		hu: "Cím",	
		en: "Title"	
	},
	pageContent: {
		cp: "Садржај",
		sr: "Sadržaj",
		sk: "Obsah",	
		ro: "Conţinut",	
		hu: "Tartalom",
		en: "Content",	
	},
	pageOrder: {
		cp: "Редни број",
		sr: "Redni broj",
		sk: "Radové číslo",	
		ro: "Numar ordinal",	
		hu: "Sorszám",	
		en: "Order",	
	},
	frontPage: {
		cp: "Пркажи на насловној страни",
		sr: "Prkaži na naslovnoj strani",
		sk: "Zobraziť na titulnej strane",	
		ro: "Afișați pe prima pagină",	
		hu: "Megjelenítés a címoldalon",	
		en: "Show on Front Page"	
	},
	active: {
		cp: "Активан",
		sr: "Aktivan",
		sk: "Aktívne",	
		ro: "Activ",	
		hu: "Aktív",
		en: "Active",	
	},
	fileUpload: {
		cp: "Слање фајлове на мрежу",
		sr: "Slanje fajlove na mrežu",
		sk: "Nahranie súboru",	
		ro: "Fișier încărcat",	
		hu: "Fájlok feltöltése",	
		en: "File Upload"	
	},
	fileType: {
		cp: "Изаберите слике и (или) документе за копирање. Дозвољено је 10 фајлова величина по  <b>5 MB</b>.<br>Слике: <b>jpg, jpeg, png</b>. Документи: <b>doc, docx, xls, xlsx, pdf</b>.",	
		sr: "Izaberite slike i (ili) dokumente za kopiranje. Dozvoljeno je 10 fajlova veličina po  <b>5 MB</b>.<br>Slike: <b>jpg, jpeg, png</b>. Dokumenti: <b>doc, docx, xls, xlsx, pdf</b>.",	
		sk: "Vyberte svoje obrázky a (alebo) dokumenty na odovzdanie. Povolených je 10 súborov, každý do veľkosti <b>5 MB</b>.<br>Obrázky: <b>jpg, jpeg, png</b>. Dokumenty: <b>doc, docx, xls, xlsx, pdf</b>.",	
		ro: "Vă rugăm să selectați fotografiile și (sau) documentele pentru încărcare. 10 fișiere permise, de până la <b>5 MB</b> fiecare.<br>Poze: <b>jpg, jpeg, png</b>. Documente: <b>doc, docx, xls, xlsx, pdf</b>.",	
		hu: "Válassza ki a feltöltendő képeket és dokumentumokat.10 fájl megengedett, egyenként <b>5 MB</b> mérettel.<br>Fényképek: <b>jpg, jpeg, png</b>. Documentumok: <b>doc, docx, xls, xlsx, pdf</b>.",	
		en: "Please select your pitures and (or) documents for upload. 10 files allowed, up to <b>5 MB</b> of  size each.<br>Pictures: <b>jpg, jpeg, png</b>. Documents: <b>doc, docx, xls, xlsx, pdf</b>."	
	},
} 
