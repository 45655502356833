<template>
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="/assets/images/slideshow-banners/banner_1900x900_1.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>{{ appMessages.appTitle.[lgs] }}</h5>
        <div class="carusel-text-2">{{ appMessages.caruselSubTitle.[lg] }}</div>
        <div class="carusel-text-3">{{ appMessages.underDevelopment.[lg] }}</div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/images/slideshow-banners/banner_1900x900_2.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>{{ appMessages.appTitle.[lgs] }}</h5>
        <div class="carusel-text-2">{{ appMessages.caruselSubTitle.[lg] }}</div>
        <div class="carusel-text-3">{{ appMessages.underDevelopment.[lg] }}</div>
      </div>
    </div>
    <div class="carousel-item">
      <img src="/assets/images/slideshow-banners/banner_1900x900_3.jpg" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
        <h5>{{ appMessages.appTitle.[lgs] }}</h5>
        <div class="carusel-text-2">{{ appMessages.caruselSubTitle.[lg] }}</div>
        <div class="carusel-text-3">{{ appMessages.underDevelopment.[lg] }}</div>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div></template>

<script>
import {appMessages} from "../../translations/messages";
export default {
  data() {
    return {
      liue: "",
		appMessages:appMessages,
//		lg:""
    };
  },
  computed: {
		 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
		 }
  },

  created() {},
  mounted() {
  	this.lg=this.$store.getters["lg"]
	let xxx=appMessages.category.[this.lg]
	//console.log("xxx")
	//console.log(xxx)
  },
  methods: {
  },
};
</script>

<style scoped>
h5 {
	color: #fff;
	font-size: 2.5rem;
	font-weight: bold;
	opacity: 0.5;
	color: #fff;
    text-shadow: #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
}
.carusel-text-2 {
	color: #fff;
	font-size: 1.1rem;
	font-weight: bold;
	opacity: 0.6;
	color: #fff;
    text-shadow: #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
}
.carusel-text-3 {
	color: #fff;
	font-size: 1.0rem;
	font-weight: normal;
	opacity: 0.6;
	color: #fff;
    text-shadow: #000 0px 0px 1px;
    -webkit-font-smoothing: antialiased;
}

</style>
