<template>
  <div>
    <section class="section section-sm">
      <div class="container">
        <!--  PICTURE GALLERY -->
        <picture-gallery :show="showPictureGallery" title="Page Images" fixed>
          <div>
            <span class="pic-navi-close d-flex justify-content-end" @click="closePicture()"><svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="display: block; fill: none; height: 16px; width: 16px; stroke: currentcolor; stroke-width: 3px; overflow: visible;" aria-hidden="true" role="presentation" focusable="false"><path d="m6 6 20 20"></path><path d="m26 6-20 20"></path></svg></span>
          </div>
          <div class="d-flex justify-content-center">
            <img class="mt-3 img-fluid" v-bind:src="currentImage" />
          </div>

          <div class="row justify-content-center">
            <button
              class="btn btn-secondary mr-2 pic-navi"
              type="button"
              :disabled="!hasPrevioustPic"
              @click="previousPicture()"
            >
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                style="
                  display: block;
                  fill: none;
                  height: 16px;
                  width: 16px;
                  stroke: currentcolor;
                  stroke-width: 3px;
                  overflow: visible;
                "
                aria-hidden="true"
                role="presentation"
                focusable="false"
              >
                <g fill="none">
                  <path
                    d="m20 28-11.29289322-11.2928932c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136l11.29289322-11.2928932"
                  ></path>
                </g>
              </svg>
            </button>
            <button
              class="btn btn-secondary ml-2 pic-navi"
              type="button"
              :disabled="!hasNextPic"
              @click="nextPicture()"
            >
              <svg
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
                style="
                  display: block;
                  fill: none;
                  height: 16px;
                  width: 16px;
                  stroke: currentcolor;
                  stroke-width: 3px;
                  overflow: visible;
                "
                aria-hidden="true"
                role="presentation"
                focusable="false"
              >
                <g fill="none">
                  <path
                    d="m12 4 11.2928932 11.2928932c.3905243.3905243.3905243 1.0236893 0 1.4142136l-11.2928932 11.2928932"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </picture-gallery>
        <!--  PICTURE GALLERY -->

        <!-- PAGE -->
        <!-- PAGE DESIGN-->
        <div>

        <div class="flex-container">
          <h2>{{ item.title }} </h2>
<div v-if="isLoggedIn" class="flex-item" @click="goToUpdatePage(item.id)"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/> <title>Edit</title></svg></div>
			 </div>
          <b>{{ appMessages.category.[lg] }}</b>
          <div v-for="item in pageCategoryPath" :key="item.id"  class="category">
          <span>{{ item }}</span>
			 </div>
          <h4 @click="convertToCirilic()">{{ item.pageDate }}</h4>

          <!-- Overview from templage-->
          <section class="section section-lg o-small-padding">
            <div class="container">
              <div class="row row-50 justify-content-center justify-content-lg-between">
                <div class="col-md-12 col-lg-12">
						<div v-html="item.content"></div>
                </div>
              </div>
            </div>
          </section>
          <!-- /Overview from templage-->

          <section class="section section-lg text-center o-small-padding">
            <div>

              <!-- DOCUMENT SET -->
              <section v-if="documents.length > 0">
                <div class="pic_gal mt-4 mb-3">
                  <h3>{{appMessages.documentsRelatedToThisPage.[lg]}}</h3>
                  <div
                    v-if="documents.length > 0"
                    class="row row-x-10 justify-content-center mt-3"
                    data-lightgallery="group"
                  >
                    <div v-for="(item, index) in documents" :key="item.id" class="col-3 col-sm-4 col-md-3 pic-ponter">
						  <a v-bind:href="`${apiUrl}/${item.path}`" target="_blank" title="Show or download this document"><img width="50" v-bind:src="`/${item.icon}`" ></a>
						 <div class="file-name"> {{item.fileName}}</div>
                    </div>
                  </div>
                  <h5 v-else>There are no images</h5>
                </div>
              </section>
              <!-- /DOCUMENTS SET -->

              <!-- IMAGE SET -->
              <section v-if="pictures.length > 0">
                <div class="pic_gal mt-5 mb-3">
                  <h3>{{appMessages.imagesRelatedToThisPage.[lg]}}</h3>
                  <div
                    v-if="pictures.length > 0"
                    class="row row-x-10 justify-content-center mt-3"
                    data-lightgallery="group"
                  >
                    <div
                      v-for="(item, index) in pictures"
                      :key="item.id"
                      class="col-3 col-sm-4 col-md-3 pic-ponter"
                    >
                      <a class="thumbnail-minimal" @click="showPicture(index)">
                        <img
                          class="thumbnail-minimal-image"
                          v-bind:src="`${apiUrl}/${item.path}`"
                          alt=""
                        />
                        <div class="thumbnail-minimal-caption"></div>
                      </a>
                    </div>
                  </div>
                  <h5 v-else>There are no pictures</h5>
                </div>
              </section>
              <!-- /IMAGE SET -->
            </div>
          </section>
          <div class="career-classic-divider"></div>
        </div>
        <!--/Items -->

        <!-- /PAGE DESIGN-->

        <!-- /PAGE -->
      </div>
    </section>
  </div>
</template>

<script>

import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
import { convertDateToLg, cyrToLat } from "../../helpers/index.js";
let images;

export default {
  data() {
    return {
		loading:"",
      currentImage: "",
     currentImageIndex: 0,
      showPictureGallery: false,
      apiUrl: "",
      showResponse: false,
		documents:[],
		pictures:[],
      images: [],
      address: "",
		item:"",
		pageCategoryPath:"",
		appMessages:appMessages,
		lg:"",
		xxx:"http://www.google.com",

    };
  },
  mounted() {
   this.$store.commit("page/setShowCarusel",false);
  	this.lg=this.$store.getters["lg"]
    const payload = this.$route.params.id;
    this.scrollTop();
    this.setImages();
  },
  created() {
    this.loadItem(this.$route.params.id)
    this.apiUrl = process.env.VUE_APP_API_URL;
    this.frontendUrl = process.env.VUE_APP_SITE_URL;
  },
  computed: {
    hasNextPic() {
      return this.currentImageIndex < this.pictures.length - 1 ? true : false;
    },
    hasPrevioustPic() {
      return this.currentImageIndex == 0 ? false : true;
    },
    apiError() {
      return this.$store.getters["page/apiError"];
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
watch: {
},

  methods: {

    async loadItem (id) {
      this.loading = true
      const r = await api.getPage(id)
		//console.log(r)
      this.item = r.page
      this.item.pageDate = convertDateToLg(this.item.pageDate,this.lg) 
      this.pageCategoryPath = r.page.categories 
      this.loading = false

		//this.documents=this.item.images;
		let fileObject=this.item.filePages;
		let iconFile="";
		// Documents
		// zip, pdf, doc, docx, xls, xlsx 

		let documents=[];
		let pictures=[];
		let documentsFileFormats=[
			'application/zip',
			'application/pdf',
			'application/msword',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
		];
		for (var key of Object.keys(fileObject)) {
			let fileName=""
			//if (p[key].format=="application/pdf") {
			if (documentsFileFormats.includes(fileObject[key].format)) {

				switch (fileObject[key].format) {
					case 'application/zip': iconFile="assets/icons/zip.svg"; break;
					case 'application/pdf': iconFile="assets/icons/pdf.svg"; break;
					case 'application/msword': iconFile="assets/icons/doc.svg"; break;
					case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': iconFile="assets/icons/doc.svg"; break;
					case 'application/vnd.ms-excel': iconFile="assets/icons/xls.svg"; break;
					case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': iconFile="assets/icons/xls.svg"; break;
					default: iconFile ="";
				}
				fileName=fileObject[key].path.substr(17)

				documents.push(JSON.parse(`{"id":${fileObject[key].id}, "path":"${fileObject[key].path}","fileName":"${fileName}", "icon": "${iconFile}"}`));


			}
		}
		this.documents=documents;
		// /Documents

		// Pictures 
		let picturesFileFormats=[ 'image/jpeg', 'image/png' ];
		for (var key of Object.keys(fileObject)) {
			if (picturesFileFormats.includes(fileObject[key].format)) {
				pictures.push(JSON.parse(`{"id":${fileObject[key].id}, "path":"${fileObject[key].path}","fileName":"${(fileObject[key].path).substr(13)}"}`));
			}

		this.pictures=pictures;
		}
		// /Pictures


    },// End of loadItem
    showPicture(i) {
      var numberOfPictures = this.pictures.length;
      this.currentImageIndex = i;
      this.currentImage = `${this.apiUrl}/${this.pictures[i].path}`;
      this.showPictureGallery = true;
    },
    closePicture(i) {
      this.showPictureGallery = false;
    },
    nextPicture() {
      var numberOfPictures = this.pictures.length;
      var i = this.currentImageIndex + 1;
      this.currentImageIndex = i;
      this.currentImage = `${this.apiUrl}/${this.pictures[i].path}`;
    },
    previousPicture() {
      var numberOfPictures = this.pictures.length;
      var i = this.currentImageIndex - 1;
      this.currentImageIndex = i;
      this.currentImage = `${this.apiUrl}/${this.pictures[i].path}`;
    },
    setImages() {
      var item = this.$store.getters["page/item"];
      this.images = item.images;
		//console.log(item.images);
    },

    goToUpdatePage(id) {
        this.$router.push({ path: `/adminupdatepage/${id}` });
    },
    goToPage(router, id) {
      if (id > 0) {
        this.$router.push({ path: `/${router}/${id}` });
      } else {
        this.$router.push({ path: `/${router}` });
      }
    },
    handleError() {
      this.$store.commit("page/setApiError", { error: false, message: "" });
      this.showResponse = false;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
	convertToCirilic () {
     const aaa = cyrToLat(this.item.title) 
     const bbb = cyrToLat(this.item.content) 
		 console.log(aaa)
		 console.log(bbb)
	 }

  }, // end of methods
};
</script>

<style scoped>
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}
form {
  margin: 1rem;
  padding: 1rem;
}
.form-control {
  margin: 0.5rem 0;
}
.error {
  color: red;
}
label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}
input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}
input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
.picture_list {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.img-container-block {
  text-align: center;
}
.img-container-inline {
  text-align: center;
  display: block;
}
.centered {
  text-align: center;
  display: block;
}
.reservation {
  text-align: center;
  display: block;
  border: 2px solid #cee5f5;
  border-radius: 12px;
  padding: 1rem;
  background-color: #e8f4fc;
}
.success {
  text-align: center;
  margin: 1rem 0;
  border: 2px solid #cee5f5;
  border-radius: 12px;
  padding: 1rem;
  background-color: #e8f4fc;
}
.comment {
  margin: 1rem 0;
  border: 2px solid #cee5f5;
  border-radius: 5px;
  padding: 1rem;
  background-color: #e8f4fc;
}
.rating {
  margin: rem 0;
  border: 2px solid #a7dbfc;
  border-radius: 12px;
  padding: 0.5rem;
  background-color: #d5e9f5;
  text-align: center;
  font-size: 20px;
}
.comment_date {
  color: #888;
}
.star_centered {
  margin: auto;
  width: 50%;
}
.page-image {
  width: 90%;
  margin: 10px;
  border-radius: 5px;
}
.address {
  font-size: 25px;
}
.gmap {
  font-size: 25px;
}
.o-small-padding {
  padding: 20px;
}
.am_type {
  font-family: "Verdana", sans-serif;
  font-size: 18px;
  font-weight: normal;
}
.am_value {
  color: #400;
  font-size: 18px;
}
.google_map {
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #ff862d;
}
.no_pointer {
  cursor: default;
}
.stars {
  width: 40%;
}
.check-date {
  font-weight: 550;
}
.please-login {
  background-color: #f8d7da;
  padding: 10px;
  padding-right: 12px;
  padding-left: 12px;
  border: 1px solid #ddd;
  border: #972124 solid 1px;
  border-radius: 5px;
  color: #972124;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.pic_gal {
  margin-top: 50;
}
.pic-navi {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #ff830a;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pic-navi-close {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin-top: 5px;
}
.pic-ponter {
  cursor: pointer;
}
.file-name {
	font-size: 0.7rem;
}
.category {
	font-size: 1.2rem;
	font-weight: bold;
	color: #cc0000;
}

.flex-container {
	display:flex;
	flex-wrap: wrap;
	justify-content: center;
}
.flex-item {
	width: 40px;
	padding: 3px;
	color: red;
	cursor: pointer;
}
</style>



