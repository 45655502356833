<template>
  <div>
    <section class="section section-sm">
      <div class="container">
        <h2 class="mb-4">{{ appMessages.dashboard.[lgs] }}</h2>
        <h4 class="mb-4">{{ appMessages.logedInUser.[lgs] }}:
        {{ logedInUserEmailComputed }}
<button type="button" class="btn btn-secondary dashboard-btn" @click="goToProfileDashboard">{{ appMessages.myProfile.[lgs] }}</button>
	<button type="button" class="btn btn-danger dashboard-btn" @click="logout">{{ appMessages.logout.[lgs] }}</button>
		  </h4>

<!--DASHBOARD -->
 <div class="dashboard-container">

 <div class="dashboard-title">{{ appMessages.pages.[lgs] }}</div>
 <div class="dashboard-flex-container">

          <div  v-if="modulePrivileges[2].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
 						<base-button type="button" @click="goToPage('admincreatepage')">{{ appMessages.createPage.[lgs] }}</base-button>
          </div>

          <div  v-if="modulePrivileges[2].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button" @click="goToPageDashboard">{{ appMessages.listPagesByCaotegory.[lgs] }}</base-button>
          </div>

          <div  v-if="modulePrivileges[2].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button" @click="updateFrontpage">{{ appMessages.updateFrontpage.[lgs] }}</base-button>
          </div>

          <div  v-if="modulePrivileges[1].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button" @click="goToPageCategoryDashboard">{{ appMessages.categories.[lgs] }}</base-button>
          </div>
</div>
<!--DASHBOARD -->
					<hr>
 <div class="dashboard-title">{{ appMessages.users.[lgs] }}</div>
 <div class="dashboard-flex-container">
          <div v-if="modulePrivileges[0].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button" @click="goToListUsers">{{ appMessages.users.[lgs] }}</base-button>
          </div>
          <div v-if="modulePrivileges[0].showButton" class="layout-bordered-item wow-outer col-lg-3 col-md-6 d-flex justify-content-center mt-3 mb-3">
            <base-button type="button"  @click="goToSignup()">{{ appMessages.newUsers.[lgs] }}</base-button>
          </div>
</div>

          </div>

      </div>
    </section>
  </div>
</template>

<script>
import api from "../../../api/client";
import {appMessages} from "../../translations/messages";
export default {
  data() {
	  return {
		appMessages:appMessages,
			lg:"sr",
      apiUrl: '',
		treeDataDB: { title: "Please Select Page Category ", id:0, children:[] },
		userModulePrivilege:[],
		modulePrivileges: [
		{"id": 1, "name": "User","showButton": false },
		{"id": 2, "name": "Page Categories","showButton": false },
		{"id": 3, "name": "Pages","showButton": false }
		],
		xxx:"xxx"

	  }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    logedInUserEmailComputed() {
      if (this.$store.getters.logedInUserEmail) {
        return this.$store.getters.logedInUserEmail;
      } else {
        return "Log In";
      }
    },
    message() {
      return this.$store.getters["page/message"];
    },
	 lgs () {
			this.lg=this.$store.getters["lg"]
	  	return this.$store.getters["lg"]
	 }
  },
  created() {
  },
  mounted() {

   this.$store.commit("page/setShowCarusel",false);
	this.$store.commit("page/deletePageCategoryIds")
	//this.userModulePrivilege=this.$store.getters.userModulePrivilege
	this.userModulePrivilege=localStorage.getItem('userModelPrivilege')

	this.modulePrivileges.forEach((element, index)=>{
			if (this.userModulePrivilege.includes(element.id)) { 
				this.modulePrivileges[index].showButton=true 
			}
	});
	//console.log(this.modulePrivileges)
  },
  methods: {

    goToPageCategoryDashboard() {
      this.$router.replace("adminpagecategorydashboard");
    },
    goToPageDashboard() {
      this.$router.replace("adminpagedashboard");
    },
    goToUserDashboard() {
      this.$router.replace("adminuserdashboard");
    },
    goToProfileDashboard() {
      this.$router.replace("profiledashboard");
    },

// **************** TEST *************************************

    test() {
		 //console.log("TEST")
    },
// **************** TEST *************************************

    goToMyPages() {
      this.$router.replace("listmypage");
    },
    goToPage(router) {
      this.$router.push({ path: `/${router}` });
      //this.$router.push({ path: `/${router}/${id}` });
    },
    goToRegisterNewPage() {
      this.$router.replace("pageregistration");
    },
    updateFrontpage() {
      this.$router.replace("/adminupdatepage/1");
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/home");
    },
    goToSignup() {
      this.$store.dispatch('logout');
      this.$router.replace("signup");
    },
    goToListUsers() {
      this.$router.replace("adminlistuser");
    },
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
ults {
  list-style-type: circle; /* Remove bullets */
  padding: 10; /* Remove padding */
  margin: 10; /* Remove margins */
}
.module-class {
 display: flex;
  width: 100%;
  font: inherit;
  border: 1px solid #aaa;
  padding: 0.15rem;
  background-color: #faf6ff;
border-radius: 8px;
}
.dashboard-btn {
margin: 0.3rem;
}
.dashboard-container {
	justify-content: center;
	margin-bottom: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dashboard-flex-container {
	display:flex;
	justify-content: center;
}
.dashboard-title {
	 font-size:1.1rem;
}
</style>
