<template>
<section class="section section-sm">
     <div class="uc-title">{{ appMessages.underConstruction.[lg] }}</div>
     <div class="uc-message">{{ appMessages.comeBackLater.[lg] }}</div>
    <div class="flex-container">
		 <img class="uc-image" src="/assets/icons/under_construction.svg" width=100px>
   </div>
  </section>
</template>

<script>
import {appMessages} from "../translations/messages";
export default {
data() {
	return { 
		appMessages:appMessages,
		lg:"",
	};
},

computed: {

},

created() {
},

mounted() {
   this.$store.commit("page/setShowCarusel",false);
  	this.lg=this.$store.getters["lg"]
},

};
</script>

<style scoped>
.flex-container { display:flex;
   flex-wrap:wrap;
   text-align:center;
	justify-content: center;
}
.uc-image {
	width: 130px;
	display:block;
	margin: 20px;
}

.uc-title {
	font-size: 2.0rem;
	font-weight: bold;
	color: #777;
	text-align:center;
}
.uc-message {
	font-size: 1.5rem;
	color: #777;
	text-align:center;
}

</style>
