<template>
  <div class="container">

		<div class="category">{{appMessages.category.[lg]}}: {{pagePath}}</div>
    <section class="section section-sm">

      <!-- Items -->
      <div>
        <div  v-if="items.pages?.length > 0" >
          <div v-for="item in items.pages" :key="'pt_'+item.id" class="item">

            <h3><router-link  :to="`/showpage/${item.id}`">{{ item.title }}</router-link></h3>

            <div class="date" v-if="showPageDate">[{{ item.pageDate }}]</div>
				<div v-html="item.content"></div>
            <hr />
          </div>
        </div>
      </div>
      <!-- Items -->

      <!-- Pagination Bar-->
      <div id="paginatin-bar"  v-if="showPaginationBar">
      <div class="pagination-container">
        <p class="mb-2"> <strong>{{ totalRecords }} Records [Page: {{currentPage}} / {{totalPages}}]</strong></p>
      </div>
      <div class="pagination-container">

<div class="page-item" id="previousDecade" v-if="showPreviousDecade">
<span class="page-link move" @click="previousDecade()">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
</svg>
</span>
</div>

            <div class="page-item" id="previousPage" v-if="showPreviousPage">
              <span class="page-link move" @click="pagination(previousPage)">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
</svg>
				  </span>
            </div>

            <div :class="item.className" v-for="item in paginationBar" :key="item.page" :id="item.page">
              <span class="page-link" @click="pagination(item.page)">{{ item.page}}</span>
            </div>

            <div class="page-item" id="nextPage" v-if="showNextPage">
              <span class="page-link move" @click="pagination(nextPage)">
<svg xmlns="http://www.w3.org/2000/svg" class="" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
</svg>
              </span>

            </div>
            <div class="page-item"  id="nextDecade" v-if="showNextDecade">
              <span class="page-link move" @click="nextDecade()">
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 25 25" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
</svg>
				  </span>
            </div>
        </div>
     </div>
      <!-- /Pagination Bar-->
    </section>
  </div>
</template>
<script>
import api from "../../../api/client";
import { appMessages } from "../../translations/messages";
import { convertDateToLg } from "../../helpers/index.js";

export default {
  data () {
    return {
		 test:[1,2,3],
      loading: false,
		showPageDate: false,
		pagePath: "",
      items: [],
      paginationBar: [],
      paginationBarAll: [],
      totalPages: 1,
      totalRecords: null,
      recordPerPage: process.env.VUE_APP_RECORD_PER_PAGE,
      currentPage: 1,
      previousPage: 1,
      nextPage: 2,

		showPaginationBar: false,

		totalDecades:1,
		currentDecade:1,

		showPreviousPage: false,
		showNextPage: true,
		showPreviousDecade: false,
		showNextDecade: false,

		firstButton:1,
		firstButtonInCurrentPaginationBar:1,
		lastButtonInCurrentPaginationBar:10,
		appMessages:appMessages,
		lg:"",
    }
  },
  created () {

    this.items=[]
    this.initialLoadItems()
  },
  mounted() {
   this.$store.commit("page/setShowCarusel",false);
  	this.lg=this.$store.getters["lg"]
	//let aaa=helpers.convertDateToLg(3,this.lg)

      for (let i = this.firstButtonInCurrentPaginationBar; i < this.lastButtonInCurrentPaginationBar+1; i++) {
        let className = "";
        if (i == 1) { className = "page-item active"; } else { className = "page-item";}
        let jo = { page: i, className: className };
        this.paginationBar.push(jo);
      }
  },

	watch: { 
		"$route.params.id"(newId, oldId) { 
			//console.log("WATCHER CALLED")
			const ppp=this.$route.path.substring( this.$route.path.indexOf("/") + 1, this.$route.path.lastIndexOf("/"));
			if (newId> 0 && ppp=="listpageincategory") {
				//this.$router.go()
				//this.$router.go(this.$router.currentRoute)
				//console.log(this.$router.currentRoute)

				this.initialLoadItems() 
			}

			//this.initialLoadItems() 
			} 
		},
  computed: {
  },
  methods: {
	  loadPaginationBar (){
		this.paginationBar=[]
      for (let i = this.firstButtonInCurrentPaginationBar; i < this.lastButtonInCurrentPaginationBar+1; i++) {
        this.paginationBar.push({ page: i, className: "page-item" });
      }
	  },



    async previousDecade() {
		this.currentDecade--
		this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
		this.lastButtonInCurrentPaginationBar=this.firstButtonInCurrentPaginationBar+9

		if (this.currentDecade == 1) {
			this.showPreviousDecade=false
			this.showNextDecade=true
		}
		else {
			//this.showPreviousDecade=false
			//this.showNextDecade=true
		}
		this.loadPaginationBar()
		this.resetPaginationBar (this.currentDecade*10)
		this.pagination(this.currentDecade*10)
	 },

    async nextDecade() {
		this.currentDecade++
		this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
		if (this.currentDecade == this.totalDecades) {
			this.lastButtonInCurrentPaginationBar=this.totalPages
		}
		if (this.currentDecade < this.totalDecades) {
			this.firstButtonInCurrentPaginationBar=(this.currentDecade-1)*10+1
			this.lastButtonInCurrentPaginationBar=this.firstButtonInCurrentPaginationBar+9
		}
		this.loadPaginationBar()
		this.resetPaginationBar (this.currentDecade*10-9)
		this.pagination(this.currentDecade*10-9)
	 },

	 resetPaginationBar (pageButton) {
		this.paginationBar.forEach((element, index)=>{
			element.className='page-item'
			if (element.page==pageButton) { element.className='page-item active' }
		});

		// Next-Previous Buttons
		if (this.currentPage == ((this.currentDecade*10)-9)) { this.showPreviousPage=false}
		else { this.showPreviousPage=true}
		if (this.currentPage == (this.currentDecade*10)) { this.showNextPage=false}
		else { this.showNextPage=true}
		if (this.currentPage ==this.totalPages ) {this.showNextPage=false}

		// Next-Previous Decade Buttons
		if (this.totalDecades > 1) { 
			if (this.currentDecade == 1) {this.showPreviousDecade=false;this.showNextDecade=true}
			if (this.currentDecade == this.totalDecades) {this.showPreviousDecade=true;this.showNextDecade=false}
			if (this.currentDecade > 1 && this.currentDecade < this.totalDecades) {this.showPreviousDecade=true;this.showNextDecade=true}
		}

	 },

    async pagination(p) {

      this.currentPage = p;
      var offset = (this.currentPage-1) * this.recordPerPage;
    	const payload = { id:this.$route.params.id, limit: this.recordPerPage, offset: offset };
      this.items = await api.listPageInCategory(payload)

      this.nextPage = p + 1;
      this.previousPage = p - 1;
      if (p == this.firstButtonInCurrentPaginationBar) { this.previousPage = p; }
      if (p == this.lastButtonInCurrentPaginationBar) { this.nextPage = p; }
		this.resetPaginationBar (p)
    },

    async initialLoadItems () {
		this.items=[]

      this.loading = true
    	const payload = { id:this.$route.params.id, limit: this.recordPerPage, offset: 0 };

      const ps = await api.listPageInCategory(payload)


		this.items=ps
		this.showPageDate=this.items.category.showPageDate
		this.pagePath=this.items.category.path
      this.loading = false

		this.items.pages.forEach((item, i, array)=> {
			array[i].pageDate=convertDateToLg(item.pageDate,this.lg)
		})
      this.totalRecords = this.items.count;
      this.totalPages = Math.ceil(this.totalRecords / this.recordPerPage);

		this.totalDecades=Math.ceil(this.totalPages / 10);

		if (this.totalDecades > this.currentDecade) { this.showNextDecade=true }
		if (this.totalDecades==1) {
			this.lastButtonInCurrentPaginationBar=this.totalPages
			this.loadPaginationBar()
			this.paginationBar[0].className="page-item active"
		}
		this.showPaginationBar=this.totalPages>1 ? true : false;
    },

  }
}
</script>

<style scoped>
.pagination-container {
	display:flex;
	justify-content: center;
	flex-wrap: wrap;
}
.move {
	width:40px;
	height:40px;
}
.item {
  margin: 1rem 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 1rem;
  background-color: #f6f6f6;
}

.category {
	font-size: 1.2rem;
	font-weight: bold;
	color: #cc0000;
}

.page-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 48px;
	min-height: 48px;
	padding: 10px 10px;
	border: 0;
	border-radius: 6px;
	font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-size: 1.rem;
	font-weight: 500;
	line-height: 1.71429;
	background-color: #022E42;
	color: #fff;
	transition: all 0.3s ease-in-out;
	margin:0.2rem;
	cursor:pointer;
}
.page-item.active .page-link {
    background-color: #bb0000;
}
.page-item:hover .page-link:hover {
    background-color: #990000;
	 color:#ffffff
}

</style>
