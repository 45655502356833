import { createRouter, createWebHistory } from 'vue-router';

import Home from '../modules/Home.vue';
import UnderConstruction from '../modules/UnderConstruction.vue';
import About from '../modules/About.vue';
import Contact from '../modules/Contact.vue';
import SignUp from '../modules/auth/SignUp.vue';
import LogIn from '../modules/auth/LogIn.vue';
import LogOut from '../modules/auth/LogOut.vue';
import Dashboard from '../modules/auth/Dashboard.vue';
import AdminUserDashboard from '../modules/auth/AdminUserDashboard.vue';
import AdminListUser from '../modules/auth/AdminListUser.vue';
import AdminUpdateUser from '../modules/auth/AdminUpdateUser.vue';
import ProfileDashboard from '../modules/auth/ProfileDashboard.vue';
import ProfileChangePassword from '../modules/auth/ProfileChangePassword.vue';
import ProfileForgotPassword from '../modules/auth/ProfileForgotPassword.vue';
import ProfileResetPassword from '../modules/auth/ProfileResetPassword.vue';

import ListPageInCategory from '../modules/page/ListPageInCategory.vue';
import ListPageInSearch from '../modules/page/ListPageInSearch.vue';
import AdminListPageInCategory from '../modules/page/AdminListPageInCategory.vue';
import ShowPage from '../modules/page/ShowPage.vue';
import AdminCreatePage from '../modules/page/AdminCreatePage.vue';
import AdminUpdatePage from '../modules/page/AdminUpdatePage.vue';
import AdminPageDashboard from '../modules/page/AdminPageDashboard.vue';
import AdminPageCategoryDashboard from '../modules/page/AdminPageCategoryDashboard.vue';

import NotFound from '../modules/NotFound.vue';
import NotTranslated from '../modules/NotTranslated.vue';

import store from '../store/index.js';

const router = createRouter({
	history: createWebHistory(),
	routes: [

		{ path: '/', redirect: '/home' },
		{ path: '/home', component: Home },
		{ path: '/uc', component: UnderConstruction },
		{ path: '/about', component: About },
		{ path: '/contact', component: Contact },

		// AUTH 
		{ path: '/signup', component: SignUp, meta: { requiresUnauth: true } },
		{ path: '/login', component: LogIn, meta: { requiresUnauth: true } },
		{ path: '/logout', component: LogOut },
		{ path: '/dashboard', component: Dashboard, meta: { requiresAuth: true } },
		{ path: '/adminuserdashboard', component: AdminUserDashboard, meta: { requiresAuth: true } },
		{ path: '/adminlistuser', component: AdminListUser, meta: { requiresAuth: true } },
		{ path: '/adminupdateuser/:id', component: AdminUpdateUser, meta: { requiresAuth: true } },
		{ path: '/profiledashboard', component: ProfileDashboard, meta: { requiresAuth: true } },
		{ path: '/profilechangepassword', component: ProfileChangePassword, meta: { requiresAuth: true } },
		{ path: '/profileforgotpassword', component: ProfileForgotPassword },
		{ path: '/profileresetpassword/:email/:tkn', component: ProfileResetPassword },
		// AUTH 

		// PAGE 
		//
		{ path: '/listpageincategory/:id', component: ListPageInCategory },
		{ path: '/listpageinsearch', component: ListPageInSearch },
		{ path: '/showpage/:id', component: ShowPage },

		{ path: '/adminlistpageincategory/:id', component: AdminListPageInCategory },
		{ path: '/adminupdatepage/:id', component: AdminUpdatePage, meta: { requiresAuth: true } },
		{ path: '/adminpagedashboard', component: AdminPageDashboard, meta: { requiresAuth: true } },
		{ path: '/adminpagecategorydashboard', component: AdminPageCategoryDashboard, meta: { requiresAuth: true } },
		{ path: '/admincreatepage', component: AdminCreatePage, meta: { requiresAuth: true } },
		{ path: '/nottranslated', component: NotTranslated },
		// PAGE 

		{ path: '/:notFound(.*)', component: NotFound }
	]
});

router.beforeEach(function(to, _, next) {
	if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
		next('/login');
		store.dispatch('logout')
	} else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
		next('/modules');
	} else {
		next();
	}
});

export default router;
