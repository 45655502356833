export const menuGlobal =  {
	 menu:
			[

			//  end 1. menu
			{
		 		cp: [
					{ id:3, title:"Култура", root:"/listpageincategory/3"},
					{ id:5, title:"Спор и омладина", root:"/listpageincategory/5"},
				], 
		 		sr: [
					{ id:3, title:"Kultura", root:"/listpageincategory/3"},
					{ id:5, title:"Sport i omladina", root:"/listpageincategory/5"},
				], 
		 		sk: [
					{ id:11, title:"Kultúra", root:"/uc"},
					{ id:12, title:"Šport a mládež", root:"uc"},
				], 
		 		ro: [
					{ id:11, title:"Cultură", root:"/uc"},
					{ id:12, title:"Sport și tineret", root:"/uc"},
				], 
		 		hu: [
					{ id:11, title:"Kultúra", root:"/uc"},
					{ id:12, title:"Sport és ifjúság", root:"/uc"},
				], 
		 		en: [
					{ id:11, title:"Culture", root:"/uc"},
					{ id:12, title:"Sport end Youth", root:"/uc"},
				] 
			},
			//  end 1. menu
			// 2. menu
			{
		 		cp: [
					{ id:12, title:"Вести", root:"/listpageincategory/12"},
					{ id:13, title:"Обавештења", root:"/listpageincategory/13"},
					{ id:14, title:"Огласи", root:"/listpageincategory/14"},
					{ id:15, title:"Конкурси", root:"/listpageincategory/15"}
				], 
		 		sr: [
					{ id:12, title:"Vesti", root:"/listpageincategory/12"},
					{ id:13, title:"Obaveštenja", root:"/listpageincategory/13"},
					{ id:14, title:"Oglasi", root:"/listpageincategory/14"},
					{ id:15, title:"Konkursi", root:"/listpageincategory/15"}
				], 
		 		sk: [
					{ id:12, title:"Novinka", root:"/uc"},
					{ id:13, title:"Upozornenia", root:"/uc"},
					{ id:14, title:"Reklamy", root:"/uc"},
					{ id:15, title:"Súťaže", root:"/uc"}
				], 
		 		ro: [
					{ id:12, title:"Știri", root:"/uc"},
					{ id:13, title:"Notificări", root:"/uc"},
					{ id:14, title:"Reclame", root:"/uc"},
					{ id:15, title:"Concursuri", root:"/uc"}
				], 
		 		hu: [
					{ id:12, title:"Hírek", root:"/uc"},
					{ id:13, title:"Értesítések", root:"/uc"},
					{ id:14, title:"Hirdetések", root:"/uc"},
					{ id:15, title:"Pályázatok", root:"/uc"}
				], 
		 		en: [
					{ id:12, title:"News", root:"/uc"},
					{ id:13, title:"Notifications", root:"/uc"},
					{ id:14, title:"Announcements", root:"/uc"},
					{ id:15, title:"Applications", root:"/uc"}
				] 
			},
			//  end 2. menu
			// 3. menu
			{
		 		cp: [
					{ id:59, title:"Локални избори 2024", root:"/listpageincategory/59"},
					{ id:16, title:"Службени лист", root:"/listpageincategory/16"},
					{ id:29, title:"Седнице Скупштине општине Ковачица", root:"/listpageincategory/29"},
					{ id:7, title:"Јавне набавке", root:"/listpageincategory/7"},
					{ id:17, title:"Буџет", root:"/listpageincategory/17"},
					{ id:36, title:"Урбанизам", root:"/showpage/276"},
					{ id:47, title:"Инспекција", root:"/listpageincategory/47"},
					{ id:11, title:"Локални омбудсман", root:"/listpageincategory/11"},
					{ id:18, title:"Важни документи", root:"/listpageincategory/18"},
					{ id:30, title:"Обједињена процедура", root:"/showpage/707"},
					{ id:31, title:"Референдум 2022", root:"/listpageincategory/31"},
					{ id:32, title:"Избори 2022", root:"/listpageincategory/32"}
				], 
		 		sr: [
					{ id:59, title:"Lokalni izbori 2024", root:"/listpageincategory/59"},
					{ id:16, title:"Službeni list", root:"/listpageincategory/16"},
					{ id:29, title:"Sednice Skupštine opštine Kovačica", root:"/listpageincategory/29"},
					{ id:7, title:"Javne nabavke", root:"/listpageincategory/7"},
					{ id:17, title:"Budžet", root:"/listpageincategory/17"},
					{ id:36, title:"Urbanizam", root:"/showpage/276"},
					{ id:47, title:"Inspekcija", root:"/listpageincategory/47"},
					{ id:11, title:"Lokalni ombudsman", root:"/listpageincategory/11"},
					{ id:18, title:"Važni dokumenti", root:"/listpageincategory/18"},
					{ id:30, title:"Objedinjena procedura", root:"/showpage/707"},
					{ id:31, title:"Referendum 2022", root:"/listpageincategory/31"},
					{ id:32, title:"Izbori 2022", root:"/listpageincategory/32"}
				], 
		 		sk: [
					{ id:59, title:"Komunálne voľby 2024", root:"/uc"},
					{ id:16, title:"Úradný vestník", root:"/uc"},
					{ id:29, title:"Zasadnutia obecného zhromaždenia Kovačica", root:"/uc"},
					{ id:7, title:"Verejné obstarávanie", root:"/uc"},
					{ id:17, title:"Rozpočet", root:"/uc"},
					{ id:36, title:"Urbanizmus", root:"/uc"},
					{ id:47, title:"Inšpekcia", root:"/uc"},
					{ id:11, title:"Miestny ombudsman", root:"/uc"},
					{ id:18, title:"Dôležité dokumenty", root:"/uc"},
					{ id:31, title:"Referendum 2022", root:"/uc"},
					{ id:32, title:"Voľby 2022", root:"/uc"}
				], 
		 		ro: [
					{ id:59, title:"Alegeri locale 2024", root:"/uc"},
					{ id:16, title:"Jurnalul Oficial", root:"/uc"},
					{ id:29, title:"Sesiuni ale Adunării Municipale din Kovačica", root:"/uc"},
					{ id:7, title:"Achiziții publice", root:"/uc"},
					{ id:17, title:"Buget", root:"/uc"},
					{ id:36, title:"Urbanism", root:"/uc"},
					{ id:47, title:"Inspecţie", root:"/uc"},
					{ id:11, title:"Ombudsmanul local", root:"/uc"},
					{ id:18, title:"Documente importante", root:"/uc"},
					{ id:31, title:"Referendum 2022", root:"/uc"},
					{ id:32, title:"Alegeri 2022", root:"/uc"}
				], 
		 		hu: [
					{ id:59, title:"Önkormányzati Választások 2024", root:"/uc"},
					{ id:16, title:"Hivatalos lap", root:"/uc"},
					{ id:29, title:"Albertfalva képviselőtestületi ülései", root:"/uc"},
					{ id:7, title:"Közbeszerzések", root:"/uc"},
					{ id:17, title:"Költségvetés", root:"/uc"},
					{ id:36, title:"Urbanizmus", root:"/uc"},
					{ id:47, title:"Ellenörzés", root:"/uc"},
					{ id:11, title:"Helyi jogvédő", root:"/uc"},
					{ id:18, title:"Fontos dokumentumok", root:"/uc"},
					{ id:31, title:"Népszavazás 2022", root:"/uc"},
					{ id:32, title:"Választások 2022", root:"/uc"}
				], 
		 		en: [
					{ id:59, title:"Local Elections 2024", root:"/uc"},
					{ id:16, title:"Official Journal", root:"/uc"},
					{ id:29, title:"Sessions of the Municipal Assembly of Kovačica", root:"/uc"},
					{ id:7, title:"Public procurement", root:"/uc"},
					{ id:17, title:"Budget", root:"/uc"},
					{ id:36, title:"Urbanism", root:"/uc"},
					{ id:47, title:"Inspection", root:"/uc"},
					{ id:11, title:"Local Ombudsman", root:"/uc"},
					{ id:18, title:"Inportant Documents", root:"/uc"},
					{ id:31, title:"Referendum 2022", root:"/uc"},
					{ id:32, title:"Elections 2022", root:"/uc"}
				] 
			},
			//  end 3. menu
			// 4. menu
			{
		 		cp: [
					{ id:4, title:"Туризам, инвестиције и међународна сарадња", root:"/listpageincategory/4"},
					{ id:9, title:"Пољопривреда", root:"/listpageincategory/9"},
					{ id:15, title:"Конкурси", root:"/listpageincategory/15"},
					{ id:20, title:"Лицитација", root:"/listpageincategory/20"},
					{ id:21, title:"Уредбе и остало", root:"/listpageincategory/21"},
					{ id:10, title:"Регистар инвестиција", root:"/listpageincategory/10"},
					{ id:22, title:"Регистар инвестиција - Црепаја", root:"/listpageincategory/22"},
					{ id:23, title:"Регистар инвестиција - Дебељача", root:"/listpageincategory/23"},
					{ id:24, title:"Регистар инвестиција - Идвор", root:"/listpageincategory/24"},
					{ id:25, title:"Регистар инвестиција - Ковачица", root:"/listpageincategory/25"}
				], 
		 		sr: [
					{ id:4, title:"Turizam, investicije i međunarodna saradnja", root:"/listpageincategory/4"},
					{ id:9, title:"Poljoprivreda", root:"/listpageincategory/9"},
					{ id:15, title:"Konkursi", root:"/listpageincategory/15"},
					{ id:20, title:"Licitacija", root:"/listpageincategory/20"},
					{ id:21, title:"Uredbe i ostalo", root:"/listpageincategory/21"},
					{ id:10, title:"Registar investicija", root:"/listpageincategory/10"},
					{ id:22, title:"Registar investicija - Crepaja", root:"/listpageincategory/22"},
					{ id:23, title:"Registar investicija - Debeljača", root:"/listpageincategory/23"},
					{ id:24, title:"Registar investicija - Idvor", root:"/listpageincategory/24"},
					{ id:25, title:"Registar investicija - Kovačica", root:"/listpageincategory/25"}
				], 
		 		sk: [
					{ id:4, title:"Cestovný ruch, investície a medzinárodná spolupráca", root:"/uc"},
					{ id:9, title:"Poľnohospodárstvo", root:"/uc"},
					{ id:15, title:"Súťaže", root:"/uc"},
					{ id:20, title:"Ponúk", root:"/uc"},
					{ id:21, title:"Nariadenie a iné", root:"/uc"},
					{ id:10, title:"Register investícií", root:"/uc"},
					{ id:22, title:"Register investícií - Crepaja", root:"/uc"},
					{ id:23, title:"Register investícií - Debeljača", root:"/uc"},
					{ id:24, title:"Register investícií - Idvor", root:"/uc"},
					{ id:25, title:"Register investícií - Kovačica", root:"/uc"}
				], 
		 		ro: [
					{ id:4, title:"Turism, investiții și cooperare internațională", root:"/uc"},
					{ id:9, title:"Agricultură", root:"/uc"},
					{ id:15, title:"Concursuri", root:"/uc"},
					{ id:20, title:"Licitaţie", root:"/uc"},
					{ id:21, title:"Reglementare și altele", root:"/uc"},
					{ id:10, title:"Registrul investițiilor", root:"/uc"},
					{ id:22, title:"Registrul investițiilor - Crepaja", root:"/uc"},
					{ id:23, title:"Registrul investițiilor - Debeljača", root:"/uc"},
					{ id:24, title:"Registrul investițiilor - Idvor", root:"/uc"},
					{ id:25, title:"Registrul investițiilor - Kovačica", root:"/uc"}
				], 
		 		hu: [
					{ id:4, title:"Turizmus, befektetések és nemzetközi együtműködés", root:"/uc"},
					{ id:9, title:"Mezőgazdaság", root:"/uc"},
					{ id:15, title:"Pályázatok", root:"/uc"},
					{ id:20, title:"Árverés", root:"/uc"},
					{ id:21, title:"Rendeletek és egyebek", root:"/uc"},
					{ id:10, title:"Befektetési nyilvántartás", root:"/uc"},
					{ id:22, title:"Befektetési nyilvántartás - Cserépalja", root:"/uc"},
					{ id:23, title:"Befektetési nyilvántartás - Torontálvásárhely", root:"/uc"},
					{ id:24, title:"Befektetési nyilvántartás - Torontáludvar", root:"/uc"},
					{ id:25, title:"Befektetési nyilvántartás - Albertfalva", root:"/uc"}
				], 
		 		en: [
					{ id:4, title:"Tourism, investments and international cooperation", root:"/uc"},
					{ id:9, title:"Agriculture", root:"/uc"},
					{ id:15, title:"Applications", root:"/uc"},
					{ id:20, title:"Auction", root:"/uc"},
					{ id:21, title:"Regulations and other", root:"/uc"},
					{ id:10, title:"Register of investments", root:"/uc"},
					{ id:22, title:"Register of investments - Crepaja", root:"/uc"},
					{ id:23, title:"Register of investments - Debeljača", root:"/uc"},
					{ id:24, title:"Register of investments - Idvor", root:"/uc"},
					{ id:25, title:"Register of investments - Kovačica", root:"/uc"}
				] 
			},
			//  end 4. menu
			]
}
