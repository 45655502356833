import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  state() {
    return {
      userEmail: null,
      userRealId: null,
      userPagePrivilege: [],
      userModulePrivilege: [],
      token: null,
      lg:"cp",
      didAutoLogout: false,
      apiSuccess: false, 
      apiError: false, 
      apiErrorMessage: "NO API Error" 
    };
  },
  mutations,
  actions,
  getters
};
