<template>
<section>
<div class="page-category-tree">
<div :class="{bold: isFolder}" id="tr" > 
<span :id="item.id" class="passivePageCategory" @click="setPageCategory(item.id)">
<input type="checkbox" :id="containerId" :value="item.id" v-model="checkedPageCategoryIds">
 {{ item.title }} - {{ item.id }} 
</span>
<span class="item"  v-if="isFolder" @click="toggle"> [{{ isOpen ? ' - ' : ' + ' }}]</span>
</div>

<ul v-show="isOpen" v-if="isFolder">
<tree-item class="item" v-for="(child, index) in item.children" :key="index" :item="child">
</tree-item>
</ul>
		
</div>
</section>
</template>

<script>
export default {
	props: {item: Object},
  data() {
    return {
      isOpen: true,
		menuClass: "passivePageCategory",
      itemsTemp: [],
      message: "",
		checkedPageCategoryIds:[],
    };
  },
  mounted() {
		document.getElementById("mt-0").style.display = 'none';
  },
  computed: {
     isFolder: function() { return this.item.children && this.item.children.length;},
     containerId: function() { 
			return typeof(this.item.id) != "undefined" ? "mt-"+this.item.id : "mt-0";
	  },
  },

  created() {
	 	const pageCategoryIds=this.$store.getters['page/pageCategoryIds']
		for (const element of pageCategoryIds) {
		  this.checkedPageCategoryIds.push(element);
		}
  },

  methods: {

	 clearPageCategorySelection() {
		for (let i = 0; i < 100; i++) { 
			if  (document.getElementById(i) != null) {
  				document.getElementById(i).classList.remove("activePageCategory");
			}
		}
	 },

   presetPageCategory () { 
		const pageCategoryIds=this.$store.getters['page/pageCategoryIds'];
		this.checkedPageCategoryIds=pageCategoryIds

	},
   toggle () { 
		if (this.isFolder) { this.isOpen = !this.isOpen;}
		this.presetPageCategory();
   },

   setPageCategory (id) { 
		this.$store.commit("page/setPageCategoryIds",id);
		console.log(this.$store.getters['page/pageCategoryIds'])
   },
  },
};
</script>

<style scoped>
.page-category-tree {
	margin: 5px;
}
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
  cursor: pointer;
}

.activePageCategory {
  font-weight: bold;
  color: #FF0000;
}
.passivePageCategory {
  font-weight: normal;
  color: #000000;
}
.bold {
  font-weight: bold;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: dot;
}
</style>
